import "./assets/scss/style.scss";
import { ToastContainer } from "react-toastify";
import { Routes, Route, HashRouter } from "react-router-dom";

import { useSelector } from "react-redux";

import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import ChangePassword from "./pages/ChangePassword";

import PrivateRoute from "./router/privateRoutes";
import Pages from "./router/index";

import RedirectListener from "./components/redirect";

function App() {
  return (
    <>
      <ToastContainer
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <HashRouter>
        <RedirectListener />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/change-password/:id?" element={<ChangePassword />} />
          <Route path="" element={<PrivateRoute />} key={"1xbt"}>
            {Pages.map((page) => (
              <Route path={page?.path} element={page?.element} key={page?.id} />
            ))}
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
