import React, { forwardRef } from "react";
import { Row, Col, Image, Card, Spinner } from "react-bootstrap";
import moment from "moment";
import {
  useGetStocksQuery,
  useGetStocksNewsQuery
} from "../../services/dashboard";

// Date formatting function
const formattedDate = (date) =>
  moment(date).format("MMMM DD YYYY [at] hh:mm A");

const Stocks = forwardRef((props, ref) => {
  const { data, isLoading } = useGetStocksQuery();
  const { data: news, isLoading: newsLoading } = useGetStocksNewsQuery();

  if (isLoading || newsLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100px" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="p-3">
      {/* News Section */}
      <div
        className="icon-hover mb-4"
        ref={ref} // Attach the forwarded ref to the news container
        style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        {news?.data?.map((newsItem, index) => (
          <Row
            className="gx-3 w-100 mb-3"
            key={index}
            onClick={() => window.open(newsItem.url, "_blank")}
          >
            <Col xs="auto">
              <Image
                src={newsItem?.image}
                className="object-fit-cover rounded-3"
                style={{ width: "50px", height: "50px" }}
                alt="News"
              />
            </Col>
            <Col>
              <p className="mb-1 small text-muted">
                {formattedDate(newsItem?.datetime)}
              </p>
              <h6 className="mt-0 mb-1">{newsItem?.headline}</h6>
              <hr />
            </Col>
          </Row>
        ))}
      </div>

      {/* Stock Prices Section */}
      <Row className="g-3 justify-content-center">
        {data?.data &&
          Object.keys(data.data).map((symbol) => (
            <Col key={symbol} xs="auto">
              <Card className="mb-0 bg-light" style={{width:'93px'}}>
                <Card.Body className="p-3">
                  <Card.Text className="text-center">
                    <h6 className="mt-0 mb-1">${data.data[symbol].currentPrice}</h6>
                    <small className="fw-semibold text-muted">{symbol}</small>
                    <div
                      className={`small ${
                        data.data[symbol].percentageChange >= 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {data.data[symbol].percentageChange}%
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
});

export default Stocks;
