import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Image, Dropdown, Modal, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import * as pdfjsLib from "pdfjs-dist/webpack";

import DeleteModal from "../Modals/DeleteItem";
import UserAccessModal from "../Modals/UserAccess";

import IMAGES from "../../assets/images";
import { checkPermissions } from "../../utils/checkPermissions";

import { Notification } from "../../components/ToastNotifcaiton";

// Set the worker src manually (if necessary)
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const GridCard = ({
  link,
  img,
  name,
  date,
  onDelete,
  Id,
  isLoading,
  updateAccess,
  accessList,
  isAccessLoading
}) => {
  const user = useSelector((state) => state.profile.user);
  const formattedDate = moment(date).format("MMMM DD YYYY [at] hh:mm A");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserAccessModal, setShowUserAccessModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [clickTimeout, setClickTimeout] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfCanvasRef = useRef(null);

  const handleDownload = useCallback((e, fileUrl) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileUrl.split("/").pop());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleClick = useCallback(
    (e) => {
      const fileUrl = img;
      const fileExtension = fileUrl
        .split(".")
        .pop()
        .toLowerCase()
        .split("?")[0];

      setDocUrl(fileUrl);
      setFileType(fileExtension);

      if (
        [
          "pdf",
          "png",
          "jpg",
          "jpeg",
          "gif",
          "svg",
          "mp4",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "doc",
          "docx"
        ].includes(fileExtension)
      ) {
        setShowDocModal(true);
      } else {
        handleDownload(e, fileUrl);
      }
    },
    [handleDownload, img]
  );

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete(Id);
    setShowDeleteModal(false);
  };

  const handleClose = () => setShowDeleteModal(false);

  const closeUserAccessModal = () => setShowUserAccessModal(false);

  const resetDocumentState = () => {
    setNumPages(null);
    setPageNumber(1);
    setShowDocModal(false);
  };

  const handleUserAccess = (e) => {
    e.stopPropagation();
    setShowUserAccessModal(true);
  };

  const getFileIcon = (fileUrl) => {
    const fileExtension = fileUrl.split(".").pop().toLowerCase().split("?")[0];
    switch (fileExtension) {
      case "pdf":
        return IMAGES.IconPdf;
      case "doc":
      case "docx":
        return IMAGES.IconDoc;
      case "xls":
      case "xlsx":
        return IMAGES.IconXls;
      case "ppt":
      case "pptx":
        return IMAGES.IconPPT;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "svg":
        return IMAGES.IconImg;
      case "zip":
        return IMAGES.IconZip;
      case "exe":
        return IMAGES.IconExe;
      default:
        return IMAGES.IconImg;
    }
  };

  const fileIcon = getFileIcon(img);

  const canDelete = checkPermissions(user, "delete_document");
  const canView = checkPermissions(user, "view_document");
  const canViewRole = checkPermissions(user, "view_roles");

  useEffect(() => {
    if (showDocModal && fileType === "pdf" && docUrl) {
      const loadPDF = async () => {
        try {
          const loadingTask = pdfjsLib.getDocument(docUrl);
          const pdf = await loadingTask.promise;

          setNumPages(pdf.numPages);

          const renderPage = async (pageNum) => {
            const page = await pdf.getPage(pageNum);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = pdfCanvasRef.current;
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };

            page.render(renderContext);
          };

          renderPage(pageNumber); // Render the initial page
        } catch (error) {
          console.error("Error loading PDF:", error);
          Notification("Failed to load document", "error");
          resetDocumentState();
        }
      };

      loadPDF();
    }
  }, [showDocModal, docUrl, fileType, pageNumber]);

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  return (
    <>
      <Card className="bg-light-hover" onClick={handleClick}>
        <Card.Body className="px-2 py-3">
          <div
            className="d-flex align-items-center justify-content-between px-2"
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex align-items-center gap-2">
              <Image src={fileIcon} className="icon-lg" alt="Guides" />
              <div>
                <h6 className="mt-0 mb-1">{name}</h6>
                <div className="d-flex align-items-center">
                  <small>{`Created - ${formattedDate}`}</small>
                </div>
              </div>
            </div>
            <Dropdown onClick={(e) => e.stopPropagation()}>
              <Dropdown.Toggle
                align="end"
                variant="transparent text-body no-arrow-dropdown p-0 border-0"
                style={{ minHeight: "auto" }}
              >
                <FeatherIcon icon="more-vertical" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {canView && (
                  <Dropdown.Item onClick={handleClick}>Preview</Dropdown.Item>
                )}
                {canView && (
                  <Dropdown.Item onClick={(e) => handleDownload(e, img)}>
                    Download
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Body>
      </Card>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleClose}
        handleDelete={handleConfirmDelete}
        isLoading={isLoading}
      />

      <UserAccessModal
        show={showUserAccessModal}
        handleClose={closeUserAccessModal}
        id={Id}
        type="File"
        updateAccess={updateAccess}
        accessList={accessList}
        isLoading={isLoading}
        isAccessLoading={isAccessLoading}
        icon={IMAGES.IconFile}
      />

      <Modal show={showDocModal} onHide={resetDocumentState} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {fileType === "pdf" ? (
            <div>
              <canvas
                ref={pdfCanvasRef}
                style={{ width: "100%", height: "auto" }}
              ></canvas>
              <div className="d-flex justify-content-between mt-2">
                <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                  Previous
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : fileType === "mp4" ? (
            <video controls style={{ width: "100%", height: "100%" }}>
              <source src={docUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : ["png", "jpg", "jpeg", "gif", "svg"].includes(fileType) ? (
            <Image src={docUrl} className="w-100" alt="Document Preview" />
          ) : ["xls", "xlsx", "ppt", "pptx", "doc", "docx"].includes(
              fileType
            ) ? (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                docUrl
              )}`}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Document Preview"
            />
          ) : (
            <p>File type not supported for preview.</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GridCard;
