import { baseApi } from "../baseApi";

export const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: "users/profile",
        method: "GET"
      }),
      providesTags: ["profile"]
    }),
    updateProfile: builder.mutation({
      query: (payload) => ({
        url: `users/update-profile`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["profile"]
    }),
    updatePassword: builder.mutation({
      query: (payload) => ({
        url: `users/update-password`,
        method: "POST",
        body: payload.body
      }),
      invalidatesTags: ["profile"]
    }),
    forgetPassword: builder.mutation({
      query: (payload) => ({
        url: `users/forgot-password`,
        method: "POST",
        body: payload.body
      }),
      invalidatesTags: ["profile"]
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: `users/reset-password/${payload.id}`,
        method: "POST",
        body: payload.body
      }),
      invalidatesTags: ["profile"]
    })
  })
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation
} = profileApi;
