import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Image,
  Modal,
  Card
} from "react-bootstrap";

import IMAGES from "../../assets/images";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

function InventoryManagement() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Image src={IMAGES.IconPlusCircle} alt="Upload Document" />
            Add New
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Terminal Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="upload" placeholder=""/>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Terminal Serial Number</Form.Label>
              <Form.Control type="text" placeholder=""/>
            </Form.Group>
            <Row className="align-items-center">
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>Merchant ID</Form.Label>
                  <Form.Control type="text" placeholder=""/>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>Merchant DBA</Form.Label>
                  <Form.Control type="text" placeholder=""/>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label>Date Purchased</Form.Label>
              <Form.Control type="date" placeholder=""/>
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleClose}>Save</Button>
        </Modal.Footer>
      </Modal>

      <h2 className="page-heading">Terminal Inventory Management</h2>
      <Row>
        <Col xs="12">
            <Row className="filter align-items-center justify-content-between gap-3 mb-3">
              <Col sm="6" xl="4" className="order-2 order-sm-1">
                <div className="icon-input">
                  <Form.Control type="search" placeholder="Search keyword" />
                  <FeatherIcon icon="search" />
                </div>
              </Col>
              <Col sm="auto" className="order-1 order-sm-2">
                <div className="d-flex justify-content-end flex-wrap gap-2">
                  <Form.Select aria-label="Default select example" style={{width: '200px'}}>
                    <option>Type</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                    <Button variant="primary" onClick={handleShow}>
                      <FeatherIcon icon="plus" />
                      Add New
                    </Button>
                </div>
              </Col>
            </Row>

            <Card className="overflow-hidden">
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check type="checkbox" />
                      </th>
                      <th>Terminal Type</th>
                      <th>Serial Number</th>
                      <th>Merchant ID</th>
                      <th>Merchant DBA</th>
                      <th>Date Deployed</th>
                      <th>Date Purchased</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width={"50px"}>
                        <Form.Check type="checkbox" />
                      </td>
                      <td>
                        <Link to="/inventory-management-detail" className="text-body">
                          <div className="d-flex align-items-center gap-2">
                            <Image src={IMAGES.IconDefault} alt='' width={'35px'} height={'35px'} className="rounded"/>
                            <span className="fw-bold">Pax A80</span>
                          </div>
                        </Link>
                      </td>
                      <td><Link to="/inventory-management-detail" className="text-body">12405625124</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">677500000000201</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">Cygnus Retail</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">05th May, 2024</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">12th May, 2024</Link></td>
                    </tr>
                    <tr>
                      <td width={"50px"}>
                        <Form.Check type="checkbox" />
                      </td>
                      <td>
                        <Link to="/inventory-management-detail" className="text-body">
                          <div className="d-flex align-items-center gap-2">
                            <Image src={IMAGES.IconDefault} alt='' width={'35px'} height={'35px'} className="rounded"/>
                            <span className="fw-bold">Pax A80</span>
                          </div>
                        </Link>
                      </td>
                      <td><Link to="/inventory-management-detail" className="text-body">12405625124</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">677500000000201</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">Cygnus Retail</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">05th May, 2024</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">12th May, 2024</Link></td>
                    </tr>
                    <tr>
                      <td width={"50px"}>
                        <Form.Check type="checkbox" />
                      </td>
                      <td>
                        <Link to="/inventory-management-detail" className="text-body">
                          <div className="d-flex align-items-center gap-2">
                            <Image src={IMAGES.IconDefault} alt='' width={'35px'} height={'35px'} className="rounded"/>
                            <span className="fw-bold">Pax A80</span>
                          </div>
                        </Link>
                      </td>
                      <td><Link to="/inventory-management-detail" className="text-body">12405625124</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">677500000000201</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">Cygnus Retail</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">05th May, 2024</Link></td>
                      <td><Link to="/inventory-management-detail" className="text-body">12th May, 2024</Link></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
        </Col>
      </Row>
    </div>
  );
}

export default InventoryManagement;
