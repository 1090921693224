import { Container, Row, Col, Image } from "react-bootstrap";

const Banner = ({ image, icon }) => {
  return (
    <div className="banner-section">
      <div
        className="banner"
        style={{
          backgroundImage: `url(${image})`,
          backgroundColor: "var(--bannerBG)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "var(--bannerHeight)"
        }}
      >
        <Container>
          <Row>
            <Col>
              <div className="banner-icon">
                <Image src={icon} alt="banner-icon" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
