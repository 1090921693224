import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DeleteModal from "../../../../components/Modals/DeleteItem";
import { AiOutlineCloseCircle } from "react-icons/ai";

const NewsModal = ({
  show,
  handleClose,
  selectedNewsId,
  handleFormSubmit,
  handleFormChange,
  imagePreviewUrls,
  handleRemoveImage,
  formData,
  isUpdating,
  isCreating,
  onDelete,
  id,
  isDeleting,
  canAdd,
  canModify,
  canDelete
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete();
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        animation={true}
        size="lg"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedNewsId ? "Edit News" : "Add News"}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Body>
            <Form.Group controlId="formImages">
              <Form.Label>Images</Form.Label>
              <Form.Control
                type="file"
                name="images"
                accept="image/*"
                onChange={handleFormChange}
                multiple
              />
              <div className="d-flex mt-3 gap-3 flex-wrap">
                {imagePreviewUrls?.map((url, index) => (
                  <div key={index} className="position-relative">
                    <img
                      src={url}
                      alt="Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        opacity: "0.5"
                      }}
                    />
                    <div
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 end-0"
                      onClick={() => handleRemoveImage(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <AiOutlineCloseCircle size={18} color="red" />
                    </div>
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group controlId="formTitle" className="mt-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleFormChange}
                rows={4}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {canDelete && (
              <div className="d-flex gap-2">
                {id && (
                  <Button
                    onClick={handleDelete}
                    variant="danger"
                    disabled={isUpdating || isCreating}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}

            {(canAdd || canModify) && (
              <Button
                variant="primary"
                type="submit"
                disabled={isUpdating || isCreating}
              >
                {isUpdating || isCreating ? (
                  <Spinner animation="border" size="sm" />
                ) : selectedNewsId ? (
                  "Save Changes"
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default NewsModal;
