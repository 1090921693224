import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Row, Button, Image } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import ComponentLoader from "../../components/Loader/ComponentLoader";
import { Notification } from "../../components/ToastNotifcaiton";
import GridCard from "../../components/Cards/GridCard";

import IMAGES from "../../assets/images";
import {
  useDeleteDocumentMutation,
  useUserAccessFileMutation
} from "../../services/documents";

const File = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { result } = location.state || {};

  const [deleteDocument, { isLoading: deleteIsLoading }] =
    useDeleteDocumentMutation();

  const [updateFileAccess, { isLoading: isFileAccessUpdating }] =
    useUserAccessFileMutation();

  const handleDeleteDoc = async (id) => {
    try {
      await deleteDocument(id).unwrap();
      Notification("Document deleted successfully", "success");
    } catch (error) {
      Notification("Failed to delete document", "error");
      console.error("Failed to delete document:", error);
    }
  };

  const handleUpdateAccessFile = async (id, accessData) => {
    try {
      await updateFileAccess({ id, classes: accessData }).unwrap();
      Notification("File Access Update successfully", "success");
      navigate("/documents-center");
    } catch (error) {
      Notification("Failed to Update File Access", "error");
      console.error("Failed to Update File Access:", error);
    }
  };

  const goBack = () => {
    navigate("/documents-center");
  };

  return (
    <div>
      <div class="page-heading d-flex align-items-top gap-3">
        {id && (
          <div onClick={goBack}>
            <Button variant="outline-light btn-icon">
              <FeatherIcon icon="chevron-left" />
            </Button>
          </div>
        )}
        <div>
          <h2 className="m-0">File</h2>
        </div>
      </div>

      <Row>
        <div className={result ? "folder-files-grids mb-5" : ""}>
          {result ? (
            <GridCard
              key={result?._id}
              name={result?.name}
              img={result?.documentLink}
              date={result?.createdAt}
              Id={result?._id}
              onDelete={handleDeleteDoc}
              isLoading={deleteIsLoading}
              updateAccess={handleUpdateAccessFile}
              accessList={result?.allowedClasses}
              isAccessLoading={isFileAccessUpdating}
            />
          ) : (
            <div className="text-center">
              <Image
                src={IMAGES.IconFile}
                className="icon-lg mb-2"
                alt="File"
              />
              <p className="fw-bold">No Document found!</p>
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default File;
