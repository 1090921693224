import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../services/auth";

const initialState = {
  user: null,
  token: null
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetAuth(state) {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        localStorage.setItem("token", payload.token);
        state.token = payload.token;
        state.user = payload;
      }
    );
  }
});

export default slice.reducer;
export const { resetAuth } = slice.actions;

// export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => {
  return state.auth.token;
};
