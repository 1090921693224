import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  Modal,
  Image,
  Accordion,
  ListGroup,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import IMAGES from "../../../../assets/images";
import { Notification } from "../../../../components/ToastNotifcaiton";

import DeleteModal from "../../../../components/Modals/DeleteItem";

import { checkPermissions } from "../../../../utils/checkPermissions";
import ChangePasswordModal from "../../../../components/Modals/ChangePassword";

const UserModal = ({
  show,
  handleClose,
  onSave,
  onUpdate,
  isLoading,
  classesData,
  userData,
  onDelete,
  handleUpdate,
  isUserUpdating,
  id,
  updatePasswordSucces,
  resetPasswordFun,
  resetPasswordLoading,
  userCreating
}) => {
  const user = useSelector((state) => state.profile.user);

  const options1 = classesData?.map((cls) => ({
    label: cls.name,
    value: cls._id
  }));

  const [selectedClasses, setSelectedClasses] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roles: []
  });
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const checkboxRefs = useRef({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showChangePasswordrModal, setShowChangePasswordrModal] =
    useState(false);

  const handleDelete = (e) => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete(userData._id);
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleShowPasswordUpdateModal = () => {
    setShowChangePasswordrModal(true);
    handleClose();
  };

  useEffect(() => {
    if (show && userData && classesData) {
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        phone: userData.phone || "",
        roles: userData?.rolesData || []
      });
      const selected = userData.roleClass
        ?.map((rcId) => {
          const found = classesData.find((cls) => cls._id === rcId);
          return found ? { label: found.name, value: found._id } : null;
        })
        .filter(Boolean);

      setSelectedClasses(selected);
    } else {
      resetForm();
    }
  }, [show, userData, classesData]);

  useEffect(() => {
    if (selectedClasses?.length > 0) {
      const newRoles = [];
      const newCheckedPermissions = {};

      selectedClasses.forEach((cls) => {
        const classData = classesData.find((c) => c._id === cls.value);
        if (classData && classData.roles) {
          classData?.roles.forEach((role) => {
            if (role.role && !newRoles.find((r) => r.role === role.role._id)) {
              const userRoleData = userData?.rolesData.find(
                (userRole) => userRole.role === role.role._id
              );

              const permissions = role.permissions.map((perm) => {
                return userRoleData
                  ? userRoleData.permissions.includes(perm)
                  : true; // Check all permissions for new users
              });

              newRoles.push({
                role: role.role._id,
                roleName: role.role.name,
                permissions: role.permissions
              });

              newCheckedPermissions[role.role._id] = {
                checked: permissions,
                isAllChecked: permissions.every(Boolean),
                isIndeterminate:
                  permissions.some(Boolean) && !permissions.every(Boolean)
              };
            }
          });
        }
      });

      setFormData((prevFormData) => ({ ...prevFormData, roles: newRoles }));
      setCheckedPermissions(newCheckedPermissions);
    }
  }, [selectedClasses, classesData, userData]);

  useEffect(() => {
    formData.roles.forEach((role) => {
      const checkState = checkedPermissions[role.role];
      if (checkboxRefs.current[role.role]) {
        checkboxRefs.current[role.role].indeterminate = checkState
          ? checkState.isIndeterminate
          : false;
      }
    });
  }, [checkedPermissions, formData.roles]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { firstName, lastName, email, phone, roles } = formData;
    if (!firstName || !lastName || !email) {
      Notification("All fields are required", "error");
      return false;
    }

    if (roles.length === 0) {
      Notification("At least one permission should be selected", "error");
      return false;
    }
    return true;
  };

  const saveUser = () => {
    if (validateForm()) {
      const roles = formData?.roles?.map((role) => ({
        role: role.role,
        permissions: checkedPermissions[role.role].checked
          ?.map((checked, idx) => (checked ? role.permissions[idx] : null))
          .filter(Boolean)
      }));
      const roleClass = selectedClasses.map((role) => role.value);
      const userPayload = { ...formData, roles, roleClass };

      if (userData) {
        userPayload.id = userData._id;
        onUpdate(userPayload);
      } else {
        onSave(userPayload);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      roles: []
    });
    setSelectedClasses([]);
    setCheckedPermissions({});
  };

  const handleCheck = (roleId, index, isChecked) => {
    const newState = { ...checkedPermissions };
    newState[roleId].checked[index] = isChecked;
    const allChecked = newState[roleId].checked.every(Boolean);
    const someChecked = newState[roleId].checked.some(Boolean);
    newState[roleId].isAllChecked = allChecked;
    newState[roleId].isIndeterminate = someChecked && !allChecked;
    setCheckedPermissions(newState);
  };

  const toggleAll = (roleId, isChecked) => {
    const newState = { ...checkedPermissions };
    newState[roleId].checked = newState[roleId].checked?.map(() => isChecked);
    newState[roleId].isAllChecked = isChecked;
    newState[roleId].isIndeterminate = false;
    setCheckedPermissions(newState);
  };

  const canDelete = checkPermissions(user, "delete_user");
  const canUpdate = checkPermissions(user, "modify_user");

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animation={true}
        size="lg"
        centered
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            <Image src={IMAGES.IconPlusCircle} alt="" />
            {userData ? "Edit User" : "Create New User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>User Class</Form.Label>
              <MultiSelect
                options={options1}
                value={selectedClasses}
                onChange={setSelectedClasses}
                labelledBy="Select"
                disabled={isLoading}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Permissions</Form.Label>
              <Accordion>
                {formData?.roles?.map((role, index) => (
                  <Accordion.Item eventKey={String(index)} key={role.role}>
                    <Accordion.Header>
                      <Form.Check
                        type="checkbox"
                        ref={(el) => (checkboxRefs.current[role.role] = el)}
                        checked={
                          checkedPermissions[role.role]?.isAllChecked || false
                        }
                        onChange={(e) => toggleAll(role.role, e.target.checked)}
                        label={role.roleName}
                        disabled={isLoading}
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <ListGroup variant="flush">
                        {role.permissions?.map((permission, idx) => (
                          <ListGroup.Item key={permission}>
                            <Form.Check
                              type="checkbox"
                              checked={
                                checkedPermissions[role.role]?.checked[idx] ||
                                false
                              }
                              onChange={(e) =>
                                handleCheck(role.role, idx, e.target.checked)
                              }
                              label={permission.replace(/_/g, " ")}
                              disabled={isLoading}
                            />
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            {canDelete && userData && (
              <Button
                onClick={handleDelete}
                variant="danger"
                disabled={userCreating || isUserUpdating}
              >
                Delete
              </Button>
            )}
            {canUpdate && userData && (
              <Button
                variant="primary"
                onClick={handleShowPasswordUpdateModal}
                disabled={userCreating || isUserUpdating}
              >
                Reset Password
              </Button>
            )}
          </div>

          <div className="d-flex gap-2">
            <Button
              variant="light"
              onClick={handleClose}
              disabled={userCreating || isUserUpdating}
            >
              Cancel
            </Button>
            {canUpdate && (
              <Button
                variant="primary"
                onClick={saveUser}
                disabled={userCreating || isUserUpdating}
              >
                {userCreating || isUserUpdating ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isLoading}
      />
      <ChangePasswordModal
        show={showChangePasswordrModal}
        handleClose={() => setShowChangePasswordrModal(false)}
        updatePasswordFunc={handleUpdate}
        type="user-profile"
        updatePasswordLoading={isUserUpdating}
        id={id}
        changePasswordVisibility={setShowChangePasswordrModal}
        resetPasswordFun={resetPasswordFun}
        resetPasswordLoading={resetPasswordLoading}
      />
    </>
  );
};

export default UserModal;
