import { baseApi } from "../baseApi";

export const pollsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPolls: builder.query({
      query: () => ({
        url: "polls/users/polls",
        method: "GET"
      }),
      providesTags: ["polls"]
    }),

    getAllPolls: builder.query({
      query: () => ({
        url: "polls/all",
        method: "GET"
      }),
      providesTags: ["polls"]
    }),

    updatePoll: builder.mutation({
      query: (payload) => ({
        url: `polls/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["polls"]
    }),
    createPoll: builder.mutation({
      query: (formData) => ({
        url: `polls/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["polls"]
    }),
    deletePoll: builder.mutation({
      query: (id) => ({
        url: `polls/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["polls"]
    }),
    submitPoll: builder.mutation({
      query: (formData) => ({
        url: `polls/${formData._id}/submit`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["polls"]
    })
  })
});

export const {
  useGetUserPollsQuery,
  useGetAllPollsQuery,
  useUpdatePollMutation,
  useCreatePollMutation,
  useDeletePollMutation,
  useSubmitPollMutation
} = pollsApi;
