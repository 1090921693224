import { baseApi } from "../baseApi";

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ({
        url: `roles/all/roles`,
        method: "GET"
      }),
      providesTags: ["roles"]
    }),
    getUsers: builder.query({
      query: () => ({
        url: `users/all`,
        method: "GET"
      }),
      providesTags: ["users"]
    }),
    getRoleClasses: builder.query({
      query: () => ({
        url: `roles/all/roles-class`,
        method: "GET"
      }),
      providesTags: ["role-classes"]
    }),
    createClass: builder.mutation({
      query: (body) => ({
        url: "roles/create/roles-class",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["role-classes"]
    }),
    getSingleClass: builder.query({
      query: (id) => ({
        url: `roles/roles-class/${id}`,
        method: "GET"
      }),
      providesTags: (result, error, id) => [{ type: "SingleClass", id }]
    }),
    updateClass: builder.mutation({
      query: (payload) => ({
        url: `roles/roles-class/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["role-classes"]
    }),
    deleteClass: builder.mutation({
      query: (id) => ({
        url: `roles/roles-class/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["role-classes"]
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "users/create",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["users"]
    }),
    getRoleClassesData: builder.query({
      query: () => ({
        url: `roles/all/roles-class/data`,
        method: "GET"
      }),
      providesTags: ["role-classes"]
    }),

    updateUser: builder.mutation({
      query: ({ id, ...userData }) => ({
        url: `users/profile/${id}`,
        method: "PATCH",
        body: userData
      }),
      invalidatesTags: ["users"]
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `users/profile/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["users"]
    }),
    resetUserPassword: builder.mutation({
      query: (payload) => ({
        url: `users/reset-user-password/${payload.id}`,
        method: "POST"
      }),
      invalidatesTags: ["profile"]
    })
  })
});

export const {
  useGetRolesQuery,
  useGetUsersQuery,
  useGetRoleClassesQuery,
  useCreateClassMutation,
  useGetSingleClassQuery,
  useUpdateClassMutation,
  useDeleteClassMutation,
  useCreateUserMutation,
  useGetRoleClassesDataQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useResetUserPasswordMutation
} = usersApi;
