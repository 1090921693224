import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Image,
  Button,
  Alert
} from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import IMAGES from "../../assets/images";
import { useForgetPasswordMutation } from "../../services/profile";
import { Notification } from "../../components/ToastNotifcaiton";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [resetPassword, { isLoading: resetPasswordLoading }] =
    useForgetPasswordMutation();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const payload = { body: { email: email } };
      await resetPassword(payload).unwrap();
      Notification(
        "Reset Password Link sent. Please check your email.",
        "success"
      );
    } catch (error) {
      Notification(
        error?.data?.error || "Failed to send reset password link",
        "error"
      );
      console.error("Reset password error:", error);
    }
  };

  return (
    <div
      className="riz-portal d-flex align-items-center justify-content-center w-100"
      style={{
        backgroundImage: `url(${IMAGES.login})`,
        backgroundColor: "var(--bs-primary)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col sm="10" md="8" lg="5">
            <Card className="card-login rounded-5 border-0">
              <Card.Body className="p-5">
                <Form onSubmit={handleResetPassword}>
                  <div className="text-center mb-4">
                    <div className="d-flex align-items-center justify-content-center w-100 mb-5">
                      <Image
                        src={IMAGES.LogoLight}
                        alt="Logo"
                        className="logo"
                      />
                    </div>
                    <h4 className="text-white">Forgot your password?</h4>
                    <p className="text-white mb-0">
                      Don’t worry! Resetting your password is easy. Just type
                      your email address below and we’ll send you a link to
                      reset your password.
                    </p>
                  </div>
                  <Form.Group className="form-group">
                    <Form.Label className="text-white">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mb-2"
                    disabled={resetPasswordLoading}
                  >
                    {resetPasswordLoading ? "Sending..." : "Send Email"}
                  </Button>
                  <p className="text-white text-center">
                    Did you remember your password?{" "}
                    <Link to="/login" className="text-white fw-bold">
                      Try logging in
                    </Link>
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
