import { baseApi } from "../baseApi";

export const dashbaordApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: () => ({
        url: "dashboard/messages",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    updateMessage: builder.mutation({
      query: (payload) => ({
        url: `dashboard/messages/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["dashboard"]
    }),
    createMessage: builder.mutation({
      query: (formData) => ({
        url: `dashboard/messages/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["dashboard"]
    }),
    deleteMessage: builder.mutation({
      query: (id) => ({
        url: `dashboard/messages/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["dashboard"]
    }),

    getNews: builder.query({
      query: () => ({
        url: "dashboard/news",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    getRecentNews: builder.query({
      query: () => ({
        url: "dashboard/news/recent",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    updateNews: builder.mutation({
      query: (payload) => ({
        url: `dashboard/news/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["dashboard"]
    }),
    createNews: builder.mutation({
      query: (formData) => ({
        url: `dashboard/news/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["dashboard"]
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: `dashboard/news/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["dashboard"]
    }),

    getEmployees: builder.query({
      query: () => ({
        url: "dashboard/employees",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    updateEmployees: builder.mutation({
      query: (payload) => ({
        url: `dashboard/employees/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["dashboard"]
    }),
    createEmployees: builder.mutation({
      query: (formData) => ({
        url: `dashboard/employees/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["dashboard"]
    }),
    deleteEmployees: builder.mutation({
      query: (id) => ({
        url: `dashboard/employees/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["dashboard"]
    }),

    getGallery: builder.query({
      query: () => ({
        url: "dashboard/gallery",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    updateGallery: builder.mutation({
      query: (payload) => ({
        url: `dashboard/gallery/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["dashboard"]
    }),
    createGallery: builder.mutation({
      query: (formData) => ({
        url: `dashboard/gallery/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["dashboard"]
    }),
    deleteGallery: builder.mutation({
      query: (id) => ({
        url: `dashboard/gallery/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["dashboard"]
    }),

    getBrochures: builder.query({
      query: () => ({
        url: "dashboard/brochures",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),

    getAlerts: builder.query({
      query: () => ({
        url: "dashboard/alert",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    updateAlert: builder.mutation({
      query: (payload) => ({
        url: `dashboard/alert/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["dashboard"]
    }),
    createAlert: builder.mutation({
      query: (formData) => ({
        url: `dashboard/alert/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["dashboard"]
    }),
    deleteAlert: builder.mutation({
      query: (id) => ({
        url: `dashboard/alert/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["dashboard"]
    }),

    getStocks: builder.query({
      query: () => ({
        url: "dashboard/stocks",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    }),
    getStocksNews: builder.query({
      query: () => ({
        url: "dashboard/stocks-news",
        method: "GET"
      }),
      providesTags: ["dashboard"]
    })
  })
});

export const {
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation,

  useGetNewsQuery,
  useGetRecentNewsQuery,
  useUpdateNewsMutation,
  useCreateNewsMutation,
  useDeleteNewsMutation,

  useGetEmployeesQuery,
  useUpdateEmployeesMutation,
  useCreateEmployeesMutation,
  useDeleteEmployeesMutation,

  useGetGalleryQuery,
  useUpdateGalleryMutation,
  useCreateGalleryMutation,
  useDeleteGalleryMutation,

  useGetBrochuresQuery,

  useGetAlertsQuery,
  useUpdateAlertMutation,
  useCreateAlertMutation,
  useDeleteAlertMutation,

  useGetStocksQuery,
  useGetStocksNewsQuery
} = dashbaordApi;
