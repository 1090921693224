// src/utils/checkPermissions.js
export const checkPermissions = (user, permissionRequired) => {
  return user?.roles?.some((role) =>
    role.permissions.includes(permissionRequired)
  );
};

export const checkPermissionsAll = (user, permissionsRequired) => {
  if (!Array.isArray(permissionsRequired)) {
    permissionsRequired = [permissionsRequired];
  }

  return permissionsRequired.some((permission) =>
    user?.roles?.some((role) => role.permissions.includes(permission))
  );
};

export const determineRoute = (user) => {
  const userAccess = checkPermissionsAll(user, [
    "view_user",
    "add_user",
    "modify_user",
    "delete_user"
  ]);

  const guideAccess = checkPermissionsAll(user, [
    "view_guide",
    "add_guide",
    "modify_guide",
    "delete_guide"
  ]);

  const documentAccess = checkPermissionsAll(user, [
    "view_document",
    "add_document",
    "modify_document",
    "delete_document"
  ]);

  if (userAccess) {
    return "/user-management";
  } else if (guideAccess) {
    return "/guides";
  } else if (documentAccess) {
    return "/documents-center";
  } else {
    return "/profile";
  }
};
