import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Row, Col, Button, Form } from "react-bootstrap";
import UploadFile from "../../assets/plugins/react-drag-drop-upload";
import AttachmentUploader from "./AttachmentUploader"; // Assuming AttachmentUploader is correctly implemented

import {
  useCreateUpdateMutation,
  useUpdateUpdateMutation,
  useGetSingleUpdateQuery,
  useDeleteUpdateMutation
} from "../../services/updates";
import { useUploadFileMutation } from "../../services/upload";
import { Notification } from "../../components/ToastNotifcaiton";
import FeatherIcon from "feather-icons-react";

import { checkPermissions } from "../../utils/checkPermissions";

import DeleteModal from "../../components/Modals/DeleteItem";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Paragraph,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo,
  Link
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { useSelector } from "react-redux";

const AddNewUpdate = ({ updateId }) => {
  const { id } = useParams();

  const user = useSelector((state) => state.profile.user);

  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [attachments, setAttachments] = useState([]);

  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const [createUpdate, { isLoading: isCreating }] = useCreateUpdateMutation();
  const [updateUpdate, { isLoading: isUpdating }] = useUpdateUpdateMutation();
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();

  const { data, isLoading } = useGetSingleUpdateQuery(id, { skip: !id });

  const [deleteUser, { isLoading: deleteIsLoading }] =
    useDeleteUpdateMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = (e) => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteUser(id).unwrap();
      Notification("Update deleted successfully", "success");
      setShowDeleteModal(false);
      navigate("/updates");
    } catch (error) {
      Notification(error?.data?.error || "Failed to delete Update", "error");
      setShowDeleteModal(false);
      console.error("Failed to delete Update:", error);
    }
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  useEffect(() => {
    if (data?.update) {
      setTitle(data.update.title);
      setImage(data.update.image);
      setContent(data.update.content);
      if (data.update.attachments) {
        setAttachments(data.update.attachments);
      }
    }
  }, [data]);

  const handleSave = async () => {
    if (editorRef.current && title) {
      let imageUrl = image;

      if (selectedFile) {
        try {
          const response = await uploadFile(selectedFile).unwrap();
          imageUrl = response.data.blobUrl;
          setImage(imageUrl);
        } catch (error) {
          Notification("Failed to upload image", "error");
          console.error("Failed to upload image:", error);
          return;
        }
      } else if (selectedFile === null && !image) {
        imageUrl = null;
      }

      const uploadedAttachments = [];
      for (const attachment of attachments) {
        if (attachment.file) {
          try {
            const response = await uploadFile(attachment.file).unwrap();
            uploadedAttachments.push({
              link: response.data.blobUrl,
              name: attachment.file.name,
              ext: attachment.ext
            });
          } catch (error) {
            Notification("Failed to upload attachment", "error");
            console.error("Failed to upload attachment:", error);
            return;
          }
        } else {
          uploadedAttachments.push(attachment);
        }
      }

      const updateData = {
        title: title,
        content: editorRef.current.getData(),
        image: imageUrl,
        attachments: uploadedAttachments
      };

      try {
        if (!id) {
          await createUpdate(updateData).unwrap();
          Notification("Update created successfully", "success");
        } else {
          await updateUpdate({ id, body: updateData }).unwrap();
          Notification("Update updated successfully", "success");
        }
        navigate("/updates");
      } catch (error) {
        Notification("Failed to save Update", "error");
        console.error("Failed to save Update:", error);
      }
    }
  };

  const cancelHandler = () => {
    navigate(-1);
  };

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "showBlocks",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "removeFormat",
        "|",
        "specialCharacters",
        "horizontalLine",
        "link",
        "insertTable",
        "highlight",
        "blockQuote",
        "codeBlock",
        "|",
        "alignment",
        "|",
        "outdent",
        "indent",
        "|",
        "accessibilityHelp"
      ],
      shouldNotGroupWhenFull: false
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Code,
      CodeBlock,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      Highlight,
      HorizontalLine,
      Indent,
      IndentBlock,
      Italic,
      Paragraph,
      RemoveFormat,
      SelectAll,
      ShowBlocks,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
      Undo,
      Link
    ],
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph"
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1"
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2"
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3"
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4"
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5"
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6"
        }
      ]
    },
    initialData: "",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file"
          }
        }
      }
    },
    placeholder: "Type or paste your content here!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties"
      ]
    }
  };

  const canEditUpdate = checkPermissions(user, "modify_content");

  return (
    <div>
      <div className="page-heading d-flex align-items-top gap-3">
        <div onClick={cancelHandler}>
          <Button variant="outline-light btn-icon">
            <FeatherIcon icon="chevron-left" />
          </Button>
        </div>
        <div>
          <h2 className="m-0">Updates</h2>
        </div>
      </div>
      <Row>
        <Col xs="12">
          <Form>
            <Form.Group className="form-group">
              <Form.Label>Update Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter update title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Upload Image</Form.Label>
              <UploadFile
                onFileSelect={setSelectedFile}
                imgLink={image}
                setImage={setImage}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Text Editor</Form.Label>

              <div
                className="editor-container editor-container_classic-editor"
                ref={editorRef}
              >
                {isLayoutReady && (
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={content}
                    onReady={(editor) => {
                      editorRef.current = editor;
                      if (content) editor.setData(content);
                    }}
                  />
                )}
              </div>
            </Form.Group>
            {canEditUpdate && (
              <AttachmentUploader
                attachments={attachments}
                setAttachments={setAttachments}
              />
            )}
            {canEditUpdate && (
              <div className="d-flex justify-content-between gap-2 mt-4">
                <div className="d-flex justify-content-end gap-2 mt-3">
                  {id && (
                    <Button
                      onClick={handleDelete}
                      variant="danger"
                      style={{ width: "100px" }}
                    >
                      Delete
                    </Button>
                  )}
                </div>

                <div className="d-flex justify-content-end gap-2 mt-3">
                  <Button
                    onClick={cancelHandler}
                    variant="light"
                    style={{ width: "100px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    variant="primary"
                    style={{ width: "100px" }}
                    disabled={isCreating || isUpdating || isUploading}
                  >
                    {isCreating || isUpdating || isUploading
                      ? "Saving..."
                      : "Save"}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </Col>
      </Row>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={deleteIsLoading}
      />
    </div>
  );
};

export default AddNewUpdate;
