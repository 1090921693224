import React from "react";
import { Table, Card } from "react-bootstrap";

// const isImageUrl = (url) => {
//   return /\.(jpg|jpeg|png|gif)$/.test(url);
// };

// const isVideoUrl = (url) => {
//   return /\.(mp4|webm|ogg)$/.test(url);
// };

// const renderCellContent = (value) => {
//   if (typeof value === "string") {
//     if (isImageUrl(value)) {
//       return (
//         <img
//           src={value}
//           alt="table content"
//           style={{ maxWidth: "100px", maxHeight: "100px" }}
//         />
//       );
//     } else if (isVideoUrl(value)) {
//       return (
//         <video controls style={{ maxWidth: "100px", maxHeight: "100px" }}>
//           <source src={value} type={`video/${value.split(".").pop()}`} />
//           Your browser does not support the video tag.
//         </video>
//       );
//     }
//   }
//   return value;
// };

const getFileExtension = (url) => {
  return url.split(".").pop().toLowerCase().split("?")[0];
};

const isImageUrl = (url) => {
  const extension = getFileExtension(url);
  return ["jpg", "jpeg", "png", "gif"].includes(extension);
};

const isVideoUrl = (url) => {
  const extension = getFileExtension(url);
  return ["mp4", "webm", "ogg"].includes(extension);
};

const renderCellContent = (value) => {
  if (typeof value === "string") {
    if (isImageUrl(value)) {
      return (
        <img
          src={value}
          alt="table content"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else if (isVideoUrl(value)) {
      return (
        <video controls style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <source src={value} type={`video/${getFileExtension(value)}`} />
          Your browser does not support the video tag.
        </video>
      );
    }
  }
  return value;
};

const ReusableTable = ({ headers, data, handleRowClick }) => {
  const renderCell = (content) => {
    if (Array.isArray(content)) {
      return (
        <div className="d-flex gap-2">
          {content.map((item, idx) => (
            <div
              key={idx}
              className="d-flex align-items-center px-3 py-2 bg-light rounded cursor-default fw-semibold text-body text-nowrap"
            >
              {item}
            </div>
          ))}
        </div>
      );
    } else {
      return renderCellContent(content);
    }
  };

  return (
    <Card className="overflow-hidden">
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className={header.widthClass}>
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item)}>
                  {headers.map((header, idx) => (
                    <td key={idx}>{renderCell(item[header.dataField])}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers?.length} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default ReusableTable;
