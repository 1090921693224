import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Notification } from "../../components/ToastNotifcaiton";
import ChangePasswordModal from "../../components/Modals/ChangePassword";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation
} from "../../services/profile";

const ProfileSettings = () => {
  const navigate = useNavigate();

  const { data, isSuccess, isLoading } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const [updatePassword, { isLoading: updatePasswordLoading }] =
    useUpdatePasswordMutation();
  const [updateProfile] = useUpdateProfileMutation();

  // State for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showChangePasswordrModal, setShowChangePasswordrModal] =
    useState(false);

  // Update state with API data when data is fetched successfully
  useEffect(() => {
    if (isSuccess && data) {
      setFirstName(data.firstName || "");
      setLastName(data.lastName || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");
    }
  }, [isSuccess, data]);

  const handleSave = async () => {
    if (firstName && lastName && email && phone) {
      const data = {
        body: {
          firstName,
          lastName,
          email,
          phone
        }
      };

      try {
        await updateProfile(data).unwrap();
        Notification("Profile Updated Successfully", "success");
      } catch (error) {
        Notification("Failed to update profile", "error");
        console.error("Failed to update profile", error);
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <h2 className="page-heading">Profile Settings</h2>

      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="8">
          <Form>
            <Row>
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group className="form-group">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Doe"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="john.doe@email.com"
                value={email}
                disabled
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="+92 345 123 456 789"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <div className={`d-flex justify-content-between gap-2`}>
              <Button
                onClick={() => setShowChangePasswordrModal(true)}
                variant="danger"
              >
                Update Password
              </Button>
              <div className="d-flex justify-content-end gap-2">
                <Button
                  onClick={goBack}
                  variant="light"
                  style={{ width: "100px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: "100px" }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      {showChangePasswordrModal && (
        <ChangePasswordModal
          show={showChangePasswordrModal}
          handleClose={() => setShowChangePasswordrModal(false)}
          updatePasswordFunc={updatePassword}
          updatePasswordLoading={updatePasswordLoading}
        />
      )}
    </div>
  );
};

export default ProfileSettings;
