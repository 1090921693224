import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Card, CardBody, Image } from "react-bootstrap";
import IMAGES from "../../assets/images";

const fetchInstagramPosts = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&access_token=${accessToken}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching Instagram posts:", error);
    return [];
  }
};

const InstagramSlider = ({ accessToken }) => {
  const [posts, setPosts] = useState([]);
  const swiperRef = useRef(null);

  useEffect(() => {
    const getInstagramPosts = async () => {
      const fetchedPosts = await fetchInstagramPosts(accessToken);
      setPosts(fetchedPosts);
    };

    getInstagramPosts();
  }, [accessToken]);

  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    }
  };

  return (
    <Card>
      <Card.Title className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <Image src={IMAGES.IconSocial} className="icon-sm" alt="" />
          Social Media
        </div>
        <div className="swiper-nav-btns">
          <button onClick={() => swiperRef.current.slidePrev()}>
            <ChevronLeftIcon className="icon-xs" />
          </button>
          <button onClick={() => swiperRef.current.slideNext()}>
            <ChevronRightIcon className="icon-xs" />
          </button>
        </div>
      </Card.Title>
      <CardBody>
        <div className="instagram-slider-container">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            pagination={pagination}
            modules={[Navigation]}
            className="mySwiper"
            style={{ width: "100%", height: "var(--instagramHeight)" }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {posts.map((post) => (
              <SwiperSlide key={post.id}>
                {post.media_type === "IMAGE" ||
                post.media_type === "CAROUSEL_ALBUM" ? (
                  <img
                    src={post.media_url}
                    alt={post.caption}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <video controls autoPlay muted style={{ width: "100%" }} loop>
                    <source src={post.media_url} type="video/mp4" />
                  </video>
                )}
                {/* <p>{post.caption}</p> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </CardBody>
    </Card>
  );
};

export default InstagramSlider;
