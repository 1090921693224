import { useState } from 'react';
import { Row,Col,Form,Card,Image,Button } from 'react-bootstrap';
import IMAGES from '../../assets/images';
import { MultiSelect } from "react-multi-select-component";

function CreateUser() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const options = [
    { label: "Agent", value: "Agent" },
    { label: "Internal", value: "Internal" },
    { label: "Admin", value: "Admin" },
  ];
  const [selected, classesSelected] = useState([]);

  return (
    <div>
      <h2 className='page-heading'>Admin Settings</h2>
      
      <Card className='section-card'>
        <div className='section-card-heading'>
          <h4 className='title'><Image src={IMAGES.IconPlusCircle} alt=''/>Create User</h4>
        </div>
        <Form>
          <Row>
            <Col sm="6">
              <Form.Group className="form-group">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group className="form-group">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="email" placeholder="" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Classes</Form.Label>
            <MultiSelect
              options={options}
              value={selected}
              onChange={classesSelected}
              labelledBy="Select"
            />
          </Form.Group>
          <div className='btn-action'>
              <Button variant="light">Cancel</Button>
              <Button variant="primary">Save</Button>
          </div>
        </Form>
      </Card>
  </div>
  );
}

export default CreateUser;
