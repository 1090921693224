import React, { useState, useRef, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import IMAGES from "../assets/images";

import Aside from "../components/Aside";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import { Container, Row, Col } from "react-bootstrap";
import { selectCurrentToken } from "../redux/slices/authSlices";

import { useGetProfileQuery } from "../services/profile";

export const getToken = () => JSON.parse(localStorage.getItem("token"));

const PrivateRoute = ({ allowedRoles }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  const [isAsideVisible, setIsAsideVisible] = useState(false);

  const asideRef = useRef(null);
  const menuButtonRef = useRef(null);

  const toggleAside = () => {
    setIsAsideVisible((prev) => !prev);
  };

  const defaultRouteData = {
    image: IMAGES.banner,
    icon: IMAGES.IconDefault
  };

  const routeData = {
    "/user-management": {
      image: IMAGES.banner,
      icon: IMAGES.IconAdminSettings
    }
  };

  // Decide which images to use based on the current route
  const currentRouteData = location.pathname.includes("guide")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconGuide
      }
    : location.pathname.includes("document")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconDocCenter
      }
    : location.pathname.includes("user")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconAdminSettings
      }
    : location.pathname.includes("file")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconDocCenter
      }
    : location.pathname.includes("update")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconUpdate
      }
    : location.pathname.includes("partner")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconPartnerCenter
      }
    : location.pathname.includes("/")
    ? {
        image: IMAGES.banner,
        icon: IMAGES.IconHome
      }
    : routeData[location.pathname] || defaultRouteData;

  const { data, isSuccess, isLoading } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const asideClass = isAsideVisible ? "aside-closed" : "aside-open";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 991 &&
        asideRef.current &&
        !asideRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target) &&
        isAsideVisible
      ) {
        setIsAsideVisible(false);
      }
    };

    // Attach event listener once when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAsideVisible]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (token || (data && data?.success && isSuccess)) {
      return (
        <div className={`riz-portal d-flex ${asideClass}`}>
          <Aside
            ref={asideRef}
            isVisible={isAsideVisible}
            setIsVisible={setIsAsideVisible}
          />
          <main>
            <div className="content">
              <Header toggleAside={toggleAside} menuButtonRef={menuButtonRef} />
              <Banner
                image={currentRouteData.image}
                icon={currentRouteData.icon}
              />
              <Container>
                <Row>
                  <Col>
                    <Outlet />
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer />
          </main>
        </div>
      );
    } else {
      return <Navigate replace to="/login" />;
    }
  }
};

export default PrivateRoute;
