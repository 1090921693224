import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Image,
  Accordion,
  Spinner
} from "react-bootstrap";

import { useGetRoleClassesQuery } from "../../../services/user";
import IMAGES from "../../../assets/images";

const UserAccessModal = ({
  show,
  handleClose,
  id,
  type,
  updateAccess,
  accessList,
  isAccessLoading,
  icon
}) => {
  const { data: classData, isLoading, refetch } = useGetRoleClassesQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (accessList && accessList.length > 0) {
      setSelectedIds(accessList);
    }
  }, [accessList]);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredClasses(classData?.data || []);
    } else {
      const filtered = classData?.data?.filter((data) =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredClasses(filtered);
    }
  }, [searchTerm, classData]);

  const handleCheckboxChange = (classId) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(classId)
        ? prevSelectedIds.filter((id) => id !== classId)
        : [...prevSelectedIds, classId]
    );
  };

  const handleConfirmUpdateAccess = async () => {
    await updateAccess(id, selectedIds);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Image src={icon} alt="Share Documents" />
          {type} Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="form-group">
            <Form.Control
              type="text"
              placeholder="Search User Classes"
              className="border-secondary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="">
            <h5 className="mb-4">Classes</h5>
            <div defaultActiveKey="0">
                {/* <Accordion.Header>Available Classes</Accordion.Header> */}
                <div eventKey="0">
                  {filteredClasses.length > 0 ? (
                    filteredClasses.map((data, index) => (
                      <Form.Check
                        key={index}
                        className="mb-3 fw-bold"
                        type="checkbox"
                        label={`${data.name}`}
                        onChange={() => handleCheckboxChange(data._id)}
                        checked={selectedIds.includes(data._id)}
                      />
                    ))
                  ) : (
                    <p>No classes found.</p>
                  )}
                </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={handleClose}
          disabled={isAccessLoading}
        >
          Cancel
        </Button>

        <Button
          disabled={isAccessLoading}
          variant="primary"
          onClick={handleConfirmUpdateAccess}
        >
          {isAccessLoading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            "Done"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserAccessModal;
