// src/features/ui/uiSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  errorMessage: "",
  redirectTo: ""
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showErrorModal: (state, action) => {
      state.showModal = true;
      state.errorMessage = action.payload;
    },
    hideErrorModal: (state) => {
      state.showModal = false;
      state.errorMessage = "";
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    clearRedirectTo: (state) => {
      state.redirectTo = "";
    }
  }
});

export const {
  showErrorModal,
  hideErrorModal,
  setRedirectTo,
  clearRedirectTo
} = uiSlice.actions;
export default uiSlice.reducer;
