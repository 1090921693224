import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Form,
  Modal,
  Image,
  Accordion,
  ListGroup,
  Spinner
} from "react-bootstrap";
import IMAGES from "../../../../assets/images";

import { Notification } from "../../../../components/ToastNotifcaiton";

import DeleteModal from "../../../../components/Modals/DeleteItem";

import { checkPermissions } from "../../../../utils/checkPermissions";

const UserClassModal = ({
  show,
  handleClose,
  rolesData,
  onSave,
  isLoading,
  classData,
  selectedClassId,
  onDelete
}) => {
  const user = useSelector((state) => state.profile.user);

  const [className, setClassName] = useState("");
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const checkboxRefs = useRef({});

  const handleDelete = (e) => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete(selectedClassId);
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  useEffect(() => {
    if (selectedClassId && classData) {
      setClassName(classData.name || "");
    } else {
      setClassName("");
    }
  }, [classData, selectedClassId]);

  useEffect(() => {
    const initialPermissions = {};
    rolesData?.forEach((role) => {
      initialPermissions[role._id] = {
        checked: new Array(role.permissions?.length).fill(false),
        isAllChecked: false,
        isIndeterminate: false
      };
    });
    setCheckedPermissions(initialPermissions);
  }, [rolesData]);

  useEffect(() => {
    if (selectedClassId && classData && rolesData) {
      const newCheckedPermissions = {};
      rolesData?.forEach((role) => {
        const rolePermissions = classData.roles.find(
          (r) => r.role._id === role._id
        );
        if (rolePermissions) {
          const checked = role.permissions?.map((permission) =>
            rolePermissions.permissions.includes(permission)
          );
          newCheckedPermissions[role._id] = {
            checked,
            isAllChecked: checked.every(Boolean),
            isIndeterminate: checked.some(Boolean) && !checked.every(Boolean)
          };
        } else {
          newCheckedPermissions[role._id] = {
            checked: new Array(role.permissions?.length).fill(false),
            isAllChecked: false,
            isIndeterminate: false
          };
        }
      });
      setCheckedPermissions(newCheckedPermissions);
    } else {
      // Reset checkedPermissions when selectedClassId is not available
      const resetPermissions = {};
      rolesData?.forEach((role) => {
        resetPermissions[role._id] = {
          checked: new Array(role.permissions?.length).fill(false),
          isAllChecked: false,
          isIndeterminate: false
        };
      });
      setCheckedPermissions(resetPermissions);
    }
  }, [classData, rolesData, selectedClassId]);

  useEffect(() => {
    rolesData?.forEach((role) => {
      const checkState = checkedPermissions[role._id];
      if (checkboxRefs.current[role._id]) {
        checkboxRefs.current[role._id].indeterminate = checkState
          ? checkState.isIndeterminate
          : false;
      }
    });
  }, [checkedPermissions, rolesData]);

  const handleCheck = (roleId, index, isChecked) => {
    const newState = { ...checkedPermissions };
    newState[roleId].checked[index] = isChecked;
    const allChecked = newState[roleId].checked.every(Boolean);
    const someChecked = newState[roleId].checked.some(Boolean);
    newState[roleId].isAllChecked = allChecked;
    newState[roleId].isIndeterminate = someChecked && !allChecked;
    setCheckedPermissions(newState);
  };

  const toggleAll = (roleId, isChecked) => {
    const newState = { ...checkedPermissions };
    newState[roleId].checked = newState[roleId].checked?.map(() => isChecked);
    newState[roleId].isAllChecked = isChecked;
    newState[roleId].isIndeterminate = false;
    setCheckedPermissions(newState);
  };

  const saveClass = () => {
    const roles = rolesData
      ?.map((role) => ({
        role: role._id,
        permissions: role.permissions.filter(
          (_, index) => checkedPermissions[role._id]?.checked[index]
        )
      }))
      .filter((role) => role.permissions?.length > 0);

    if (!className) {
      Notification("Name must be added", "error");
    } else if (roles?.length === 0) {
      Notification("At least a permission must be selected", "error");
    } else {
      onSave({
        name: className,
        roles
      })
        .then((success) => {
          if (success) handleClose(); // Close modal only on success
        })
        .catch((error) => {
          console.error("Error saving class:", error);
        });
    }
  };

  const canDelete = checkPermissions(user, "delete_roles");

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animation={true}
        size="lg"
        centered
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            <Image src={IMAGES.IconPlusCircle} alt="Create User Class" />
            {selectedClassId ? "Edit User Class" : "Create User Class"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>User Class</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter class name"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                disabled={isLoading}
              />
            </Form.Group>
            <Accordion defaultActiveKey="0">
              {rolesData?.map((role, index) => (
                <Accordion.Item eventKey={String(index)} key={role._id}>
                  <Accordion.Header>
                    <Form.Check
                      type="checkbox"
                      ref={(el) => (checkboxRefs.current[role._id] = el)}
                      checked={
                        checkedPermissions[role._id]?.isAllChecked || false
                      }
                      onChange={(e) => toggleAll(role._id, e.target.checked)}
                      label={role.name}
                      disabled={isLoading}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush">
                      {role.permissions?.map((permission, idx) => (
                        <ListGroup.Item key={permission}>
                          <Form.Check
                            type="checkbox"
                            checked={
                              checkedPermissions[role._id]?.checked[idx] ||
                              false
                            }
                            onChange={(e) =>
                              handleCheck(role._id, idx, e.target.checked)
                            }
                            label={permission.replace(/_/g, " ")}
                            disabled={isLoading}
                          />
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Form>
          {isLoading && (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {selectedClassId && canDelete ? (
            <Button
              variant="danger"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          ) : (
            <div></div>
          )}

          <div className="d-flex gap-2">
            <Button variant="light" onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="primary" onClick={saveClass} disabled={isLoading}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isLoading}
      />
    </>
  );
};

export default UserClassModal;
