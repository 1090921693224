import { baseApi } from "../baseApi";

export const updatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpdates: builder.query({
      query: (searchParams) => {
        // Construct the query string based on the searchParams provided
        const queryParams = new URLSearchParams();

        if (searchParams?.name) {
          queryParams.append("name", searchParams.name);
        }
        if (searchParams?.page) {
          queryParams.append("page", searchParams.page);
        } else {
          queryParams.append("page", 1); // default to first page
        }
        if (searchParams?.limit) {
          queryParams.append("limit", searchParams.limit);
        } else {
          queryParams.append("limit", 10); // default limit to 10
        }

        return {
          url: `updates/all?${queryParams.toString()}`,
          method: "GET"
        };
      },
      providesTags: ["updates"]
    }),

    getRecentUpdates: builder.query({
      query: (searchParams) => {
        // Construct the query string based on the searchParams provided
        const queryString =
          searchParams && searchParams.name
            ? `?name=${encodeURIComponent(searchParams.name)}`
            : "";
        return {
          url: `updates/recent`,
          method: "GET"
        };
      },
      providesTags: ["updates"]
    }),

    getSingleUpdate: builder.query({
      query: (id) => ({
        url: `updates/${id}`,
        method: "GET"
      }),
      providesTags: ["updates"]
    }),

    createUpdate: builder.mutation({
      query: (body) => ({
        url: "updates/create",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["updates"]
    }),

    deleteUpdate: builder.mutation({
      query: (id) => ({
        url: `updates/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["updates"]
    }),

    updateUpdate: builder.mutation({
      query: (payload) => ({
        url: `updates/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["updates"]
    })
  })
});

export const {
  useGetUpdatesQuery,
  useGetRecentUpdatesQuery,
  useGetSingleUpdateQuery,
  useCreateUpdateMutation,
  useDeleteUpdateMutation,
  useUpdateUpdateMutation
} = updatesApi;
