import { createSlice } from "@reduxjs/toolkit";
import { profileApi } from "../../services/profile";

const initialState = {
  user: null
};

const slice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    resetProfile(state) {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      }
    );
  }
});

export default slice.reducer;
export const { resetProfile } = slice.actions;

export const selectCurrentUser = (state) => state.profile.user;
