// Polls.jsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import PollModal from "./pollModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetAllPollsQuery,
  useUpdatePollMutation,
  useCreatePollMutation,
  useDeletePollMutation
} from "../../../../services/polls";

import { checkPermissions } from "../../../../utils/checkPermissions";

const Polls = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedPollId, setSelectedPollId] = useState(null);
  const [formData, setFormData] = useState({
    question: "",
    options: [],
    additionalQuestions: [],
    ratingEnabled: false,
    ratingQuestions: []
  });
  const [isPollLoading, setIsPollLoading] = useState(false);

  const { data, isLoading } = useGetAllPollsQuery(undefined);
  const [updatePoll, { isLoading: isUpdating }] = useUpdatePollMutation();
  const [createPoll, { isLoading: isCreating }] = useCreatePollMutation();
  const [deletePoll, { isLoading: isDeleting, refetch }] =
    useDeletePollMutation();

  useEffect(() => {
    if (selectedPollId) {
      const selectedPoll = data?.data.find(
        (item) => item._id === selectedPollId
      );
      if (selectedPoll) {
        setFormData({
          question: selectedPoll.question,
          options: selectedPoll.options.map((opt) => opt.text) || [],
          additionalQuestions: selectedPoll.additionalQuestions || [],
          ratingEnabled: selectedPoll.ratings.length > 0,
          ratingQuestions: selectedPoll.ratings.map((rat) => rat.question) || []
        });
      }
    } else {
      setFormData({
        question: "",
        options: [],
        additionalQuestions: [],
        ratingEnabled: false,
        ratingQuestions: []
      });
    }
  }, [selectedPollId, data]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedPollId(null);
    setFormData({
      question: "",
      options: [],
      additionalQuestions: [],
      ratingEnabled: false,
      ratingQuestions: []
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormSubmit = async (payload) => {
    setIsPollLoading(true);

    try {
      if (selectedPollId) {
        await updatePoll(payload);
        Notification("Poll Updated successfully", "success");
      } else {
        await createPoll(payload.body);
        Notification("Poll Added successfully", "success");
      }
      handleClose();
    } catch (error) {
      Notification("Error while submitting", "error");
      console.error("Error saving poll:", error);
    } finally {
      setIsPollLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deletePoll(selectedPollId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete Poll:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedPollId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Question", dataField: "question", widthClass: "w-75" },
    {
      label: "Submission Count",
      dataField: "submissionCount",
      widthClass: "w-25"
    }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedPollId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data.map((poll) => ({
          ...poll,
          submissionCount: poll.submissionCount || 0
        }))}
        handleRowClick={handleRowClick}
      />

      <PollModal
        show={show}
        handleClose={handleClose}
        selectedPollId={selectedPollId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        formData={formData}
        isUpdating={isPollLoading}
        isCreating={isPollLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedPollId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default Polls;
