import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import MessageModal from "./messageModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation
} from "../../../../services/dashboard";
import { useUploadFileMutation } from "../../../../services/upload";

import { usersApi } from "../../../../services/user";

import { checkPermissions } from "../../../../utils/checkPermissions";

const Executive = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    title: "",
    message: ""
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isExecutiveLoading, setIsExecutiveLoading] = useState(false);

  const { data, isLoading } = useGetMessagesQuery(undefined);
  const [updateMessage, { isLoading: isUpdating }] = useUpdateMessageMutation();
  const [createMessage, { isLoading: isCreating }] = useCreateMessageMutation();
  const [deleteMessage, { isLoading: isDeleting, refetch }] =
    useDeleteMessageMutation();

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (selectedClassId) {
      const selectedClass = data?.data.find(
        (item) => item._id === selectedClassId
      );
      if (selectedClass) {
        setFormData({
          image: selectedClass.image,
          title: selectedClass.title,
          name: selectedClass.name,
          message: selectedClass.message
        });
        setImagePreviewUrl(selectedClass.image);
      }
    } else {
      setFormData({ image: "", name: "", message: "" });
      setImagePreviewUrl("");
    }
  }, [selectedClassId, data]);

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([
        { type: "SingleClass", id: selectedClassId }
      ])
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectedClassId(null);
    setFormData({ image: "", name: "", message: "", title: "" });
    setImagePreviewUrl("");
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files.length > 0) {
      const file = files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setFormData((prev) => ({
        ...prev,
        image: file
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsExecutiveLoading(true);

    // Check if all fields are present
    if (
      !formData.name ||
      !formData.title ||
      !formData.message ||
      !formData.image
    ) {
      Notification("All fields are required", "error");
      return;
    }

    if (formData.image && typeof formData.image === "object") {
      // Check if the image is a file object
      try {
        const uploadResponse = await uploadFile(formData.image).unwrap();
        const imageLink = uploadResponse.data.blobUrl;

        // Create the payload with the uploaded image link
        const payload = {
          name: formData.name,
          title: formData.title,
          message: formData.message,
          image: imageLink
        };

        if (selectedClassId) {
          await updateMessage({ id: selectedClassId, payload });
          setIsExecutiveLoading(false);
        } else {
          await createMessage(payload);
          setIsExecutiveLoading(false);
        }

        handleClose();
      } catch (error) {
        console.error("Error saving class:", error);
        setIsExecutiveLoading(false);
      }
    } else {
      // Handle case where no new image is uploaded, but still perform update or create
      const payload = {
        name: formData.name,
        title: formData.title,
        message: formData.message,
        image: formData.image // This would be the existing image link
      };

      try {
        if (selectedClassId) {
          await updateMessage({ id: selectedClassId, payload });
          Notification("Executive Updated successfully", "success");
        } else {
          await createMessage(payload);
          Notification("Executive Added successfully", "success");
        }

        handleClose();
      } catch (error) {
        Notification("Error while submitting", "error");
        console.error("Error saving class:", error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteMessage(selectedClassId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete Executive:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedClassId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Image", dataField: "image", widthClass: "w-25" },
    { label: "Name", dataField: "name", widthClass: "w-10" },
    { label: "Title", dataField: "title", widthClass: "w-15" },
    { label: "Message", dataField: "message", widthClass: "w-50" }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedClassId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />

      <MessageModal
        show={show}
        handleClose={handleClose}
        selectedClassId={selectedClassId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        imagePreviewUrl={imagePreviewUrl}
        formData={formData}
        isUpdating={isExecutiveLoading}
        isCreating={isExecutiveLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedClassId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default Executive;
