import React from 'react';

const PollSettings = () => {
  return (
  <div>

  </div>
  );
};

export default PollSettings;