import React from "react";
import { Modal, Button, Image, Spinner } from "react-bootstrap";
import IMAGES from "../../../assets/images";

const DeleteModal = ({ show, handleClose, handleDelete, isLoading }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      animation={true}
      centered
    >
      <Modal.Header
        className="border-0 position-absolute w-100 z-1"
        closeButton
      ></Modal.Header>
      <Modal.Body className="pb-5">
        <div className="d-flex flex-column text-center my-4">
          <Image
            src={IMAGES.IconCrossCircle}
            className="icon-lg mx-auto"
            alt="User Management"
          />
          <div className="mt-4 mb-3">
            <h3 className="mt-0">Are you sure?</h3>
            You won’t be able to revert this!
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="danger"
            disabled={isLoading}
            onClick={handleDelete}
            style={{ minWidth: "120px" }}
          >
            {isLoading ? (
              <Spinner color="light" size="sm" />
            ) : (
              "Yes, delete it!"
            )}
          </Button>
          <Button
            variant="light"
            disabled={isLoading}
            onClick={handleClose}
            style={{ minWidth: "120px" }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
