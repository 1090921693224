import { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Button, Dropdown } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import DeleteModal from "../Modals/DeleteItem";
import UserAccessModal from "../Modals/UserAccess";

import IMAGES from "../../assets/images";

import { checkPermissions } from "../../utils/checkPermissions";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const ListCard = ({
  link,
  img,
  name,
  date,
  editLink,
  Id,
  onDelete,
  isLoading,
  updateAccess,
  accessList,
  isAccessLoading
}) => {
  const user = useSelector((state) => state.profile.user);

  const navigate = useNavigate();
  const formattedDate = moment(date).format("DD MMMM YYYY [at] hh:mm A");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserAccessModal, setShowUserAccessModal] = useState(false);

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(editLink);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete(Id);
    setShowDeleteModal(false);
  };

  const handleClose = () => setShowDeleteModal(false);

  const closeUserAccessModal = () => setShowUserAccessModal(false);

  const handleUserAccess = (e) => {
    e.stopPropagation();
    setShowUserAccessModal(true);
  };

  const canDelete = checkPermissions(user, "delete_guide");
  const canEdit = checkPermissions(user, "modify_guide");

  return (
    <>
      <Card className="mb-2">
        <Card.Body className="d-flex align-items-center justify-content-between">
          <div className="w-100">
            <ConditionalWrapper
              condition={!!link}
              wrapper={(children) => (
                <Link to={link} className="w-100">
                  {children}
                </Link>
              )}
            >
              <h6 className="mt-0 mb-1">{name}</h6>
              <p className="mb-0 small text-muted">{formattedDate}</p>
            </ConditionalWrapper>
          </div>

          <Dropdown onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle
              align="end"
              variant="transparent text-body no-arrow-dropdown p-0 border-0"
              style={{ minHeight: "auto" }}
            >
              <FeatherIcon icon="more-vertical" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {canDelete && (
                <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
              )}
              {canEdit && (
                <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
              )}
              {canEdit && (
                <Dropdown.Item onClick={handleUserAccess}>
                  User Access
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Card.Body>
      </Card>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleClose}
        handleDelete={handleConfirmDelete}
        isLoading={isLoading}
      />

      <UserAccessModal
        show={showUserAccessModal}
        handleClose={closeUserAccessModal}
        id={Id}
        type="Guide"
        updateAccess={updateAccess}
        accessList={accessList}
        isAccessLoading={isAccessLoading}
        icon={IMAGES.IconGuide}
      />
    </>
  );
};

export default ListCard;
