import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Card,
  Modal,
  Image
} from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import FeatherIcon from "feather-icons-react";
import ComponentLoader from "../../components/Loader/ComponentLoader";
import { Notification } from "../../components/ToastNotifcaiton";
import DocumentUploadModal from "../../components/Modals/DocumentUpload";
import CreateFolderModal from "../../components/Modals/CreateFolder";
import UpdateDocumentModal from "./UpdateDocumentModal";
import DeleteModal from "../../components/Modals/DeleteItem";
import UserAccessModal from "../../components/Modals/UserAccess";
import IMAGES from "../../assets/images";
import * as pdfjsLib from "pdfjs-dist/webpack";

import {
  useDeleteDocumentMutation,
  useDeleteFolderMutation,
  useUserAccessFolderMutation,
  useUserAccessFileMutation,
  useGetFoldersDocumentsQuery,
  useDeleteFileMutation,
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation
} from "../../services/documents";
import { checkPermissions } from "../../utils/checkPermissions";

import moment from "moment";

// Set the worker src manually (if necessary)
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const isNewDocument = (createdAt) => {
  if (!createdAt) {
    return false;
  }
  return moment().diff(moment(createdAt), "days") <= 7;
};

function DocumentsCenter() {
  const { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.profile.user);

  // Define permissions
  const canAddDocument = checkPermissions(user, "add_document");
  const canModifyDocument = checkPermissions(user, "modify_document");
  const canDeleteDocument = checkPermissions(user, "delete_document");

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [folderData, setFolderData] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [viewMode, setViewMode] = useState("grid");
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserAccessModal, setShowUserAccessModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [showUpdateDocumentModal, setShowUpdateDocumentModal] = useState(false);
  const [documentToUpdate, setDocumentToUpdate] = useState(null);

  const [showDocModal, setShowDocModal] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [isDocLoading, setIsDocLoading] = useState(false); // Loader state
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfCanvasRef = useRef(null);

  const {
    data: foldersfilesData,
    isLoading: foldersfilesIsLoading,
    refetch,
    isFetching
  } = useGetFoldersDocumentsQuery({ name: submittedSearch, parentFolder: id });

  const [deleteDocument, { isLoading: deleteIsLoading }] =
    useDeleteDocumentMutation();

  const [deleteFile, { isLoading: deleteFileIsLoading }] =
    useDeleteFileMutation();

  const [deleteFolder, { isLoading: deleteFolderIsLoading }] =
    useDeleteFolderMutation();

  const [updateFolderAccess, { isLoading: isFolderAccessUpdating }] =
    useUserAccessFolderMutation();

  const [updateFileAccess, { isLoading: isFileAccessUpdating }] =
    useUserAccessFileMutation();

  const [createFolder, { isLoading: isCreating }] = useCreateFolderMutation();

  const handleModal = () => setShowModal(!showModal);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value === "") {
      setSubmittedSearch("");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSubmittedSearch(searchInput);
  };

  const handleDeleteFile = async () => {
    try {
      await deleteFile(deleteTarget).unwrap();
      Notification("Document deleted successfully", "success");
      refetch();
    } catch (error) {
      Notification("Failed to delete document", "error");
      console.error("Failed to delete document:", error);
    }
    setShowDeleteModal(false);
    setDeleteTarget(null);
  };

  const handleDeleteFolder = async () => {
    setIsDeleting(true);
    try {
      await deleteFolder(deleteTarget.folderId).unwrap();
      Notification("Folder deleted successfully", "success");
      refetch();
    } catch (error) {
      Notification("Failed to delete folder", "error");
      console.error("Failed to delete folder:", error);
    }
    setIsDeleting(false);
    setShowDeleteModal(false);
    setDeleteTarget(null);
  };

  const handleUpdateAccessFolder = async (id, accessData) => {
    try {
      await updateFolderAccess({ id, classes: accessData }).unwrap();
      Notification("Folder Access Update successfully", "success");
      refetch();
    } catch (error) {
      Notification("Failed to Update folder Access", "error");
      console.error("Failed to Update folder Access:", error);
    }
  };

  const handleUpdateAccessFile = async (id, accessData) => {
    try {
      await updateFileAccess({ id, classes: accessData }).unwrap();
      Notification("File Access Update successfully", "success");
      refetch();
    } catch (error) {
      Notification("Failed to Update File Access", "error");
      console.error("Failed to Update File Access:", error);
    }
  };

  const handleRenameFolder = (e, folderData) => {
    e.stopPropagation();
    setFolderData(folderData);
    setShowCreateFolderModal(true);
  };

  const handleDownload = (e, fileUrl) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileUrl.split("/").pop());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = (e, folderId, documentId) => {
    e.stopPropagation();
    setDeleteTarget({ folderId, documentId });
    setShowDeleteModal(true);
  };

  const handleUserAccess = (e) => {
    e.stopPropagation();
    setShowUserAccessModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteTarget(null);
  };

  const closeUserAccessModal = () => setShowUserAccessModal(false);

  const handleUpdateDocumentModal = (folderId, document) => {
    setDocumentToUpdate({ ...document, folderId });
    setShowUpdateDocumentModal(true);
  };

  const closeUpdateDocumentModal = () => {
    setShowUpdateDocumentModal(false);
    setDocumentToUpdate(null);
  };

  const handleViewDocument = (e, fileUrl) => {
    e.stopPropagation();
    const fileExtension = fileUrl.split(".").pop().toLowerCase().split("?")[0];

    setDocUrl(fileUrl);
    setFileType(fileExtension);
    setIsDocLoading(true);
    setShowDocModal(true);
  };

  const toggleVisibility = (folderName) => {
    setVisibilityMap((prevMap) => ({
      ...prevMap,
      [folderName]: !prevMap[folderName]
    }));
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const loadPDF = async () => {
      if (showDocModal && fileType === "pdf" && docUrl) {
        try {
          const loadingTask = pdfjsLib.getDocument(docUrl);
          const pdf = await loadingTask.promise;

          setNumPages(pdf.numPages);

          const renderPage = async (pageNum) => {
            const page = await pdf.getPage(pageNum);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = pdfCanvasRef.current;
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };

            page.render(renderContext);
          };

          renderPage(pageNumber); // Render the initial page
          setIsDocLoading(false); // Stop loader
        } catch (error) {
          console.error("Error loading PDF:", error);
          setIsDocLoading(false); // Stop loader in case of error
        }
      }
    };

    loadPDF();
  }, [showDocModal, docUrl, fileType, pageNumber]);

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const resetDocumentState = () => {
    setNumPages(null);
    setPageNumber(1);
    setShowDocModal(false);
  };

  if ((foldersfilesIsLoading || isFetching) && !isUploading) {
    return <ComponentLoader />;
  }

  const renderFoldersAndDocuments = (folders, isFirstLevel = true) => {
    return folders?.map((folder) => (
      <Card className="mb-0 card-dropdown-hover" key={folder._id}>
        <Card.Body className={isFirstLevel ? "p-3" : "p-0"}>
          {isFirstLevel ? (
            <>
              <Card.Title className="m-0 p-0">
                <div className="d-flex align-items-center justify-content-between list-item">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="text-primary">{folder.name}</span>
                    {isNewDocument(folder?.createdAt) && (
                      <span
                        className="ms-1 text-primary text-uppercase fw-bold"
                        style={{ fontSize: "8px" }}
                      >
                        New
                      </span>
                    )}
                  </div>

                  <Dropdown>
                    <Dropdown.Toggle
                      align="end"
                      variant="transparent text-body no-arrow-dropdown p-0 border-0"
                      style={{ minHeight: "auto" }}
                    >
                      <FeatherIcon icon="more-vertical" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {canAddDocument && (
                        <Dropdown.Item
                          onClick={() => {
                            setFolderData(null);
                            setParentId(folder._id);
                            setShowCreateFolderModal(true);
                          }}
                        >
                          Add Folder
                        </Dropdown.Item>
                      )}
                      {canAddDocument && (
                        <Dropdown.Item
                          onClick={() => {
                            handleModal();
                            setParentId(folder._id);
                          }}
                        >
                          Upload Documents
                        </Dropdown.Item>
                      )}
                      {canModifyDocument && (
                        <Dropdown.Item
                          onClick={(e) => handleRenameFolder(e, folder)}
                        >
                          Rename
                        </Dropdown.Item>
                      )}
                      {canDeleteDocument && (
                        <Dropdown.Item
                          onClick={(e) => handleDelete(e, folder._id, null)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                      {/* {canModifyDocument && (
                        <Dropdown.Item onClick={handleUserAccess}>
                          User Access
                        </Dropdown.Item>
                      )} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Title>
              {folder.documents && (
                <ul className="list-1 mt-4 mb-3 ms-4">
                  {folder.documents.map((doc) => (
                    <li key={doc._id}>
                      <div className="d-flex align-items-center justify-content-between list-item">
                        <Link
                          onClick={(e) =>
                            handleViewDocument(e, doc.documentLink)
                          }
                        >
                          <span>{doc.name}</span>

                          {isNewDocument(doc?.created_at) && (
                            <span
                              className="ms-1 text-primary text-uppercase fw-bold"
                              style={{ fontSize: "8px" }}
                            >
                              New
                            </span>
                          )}
                        </Link>
                        <Dropdown>
                          <Dropdown.Toggle
                            align="end"
                            variant="transparent text-body no-arrow-dropdown p-0 border-0"
                            style={{ minHeight: "auto" }}
                          >
                            <FeatherIcon icon="more-vertical" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleDownload(e, doc.documentLink)
                              }
                            >
                              Download
                            </Dropdown.Item>
                            {canModifyDocument && (
                              <Dropdown.Item
                                onClick={() =>
                                  handleUpdateDocumentModal(folder._id, doc)
                                }
                              >
                                Rename
                              </Dropdown.Item>
                            )}
                            {canDeleteDocument && (
                              <Dropdown.Item
                                onClick={(e) =>
                                  handleDelete(e, folder?._id, doc?._id)
                                }
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                            {/* {canModifyDocument && (
                              <Dropdown.Item onClick={handleUserAccess}>
                                User Access
                              </Dropdown.Item>
                            )} */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-between list-item">
              <div className="d-flex align-items-center gap-2">
                <div onClick={() => toggleVisibility(folder.name)}>
                  {visibilityMap[folder.name] ? (
                    <FeatherIcon className="icon-hover" icon="minus" />
                  ) : (
                    <FeatherIcon className="icon-hover" icon="plus" />
                  )}
                </div>
                <p className="mb-0">{folder.name}</p>
              </div>

              <Dropdown>
                <Dropdown.Toggle
                  align="end"
                  variant="transparent text-body no-arrow-dropdown p-0 border-0"
                  style={{ minHeight: "auto" }}
                >
                  <FeatherIcon icon="more-vertical" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {canAddDocument && (
                    <Dropdown.Item
                      onClick={() => {
                        setFolderData(null);
                        setParentId(folder._id);
                        setShowCreateFolderModal(true);
                      }}
                    >
                      Add Folder
                    </Dropdown.Item>
                  )}

                  {canAddDocument && (
                    <Dropdown.Item
                      onClick={() => {
                        handleModal();
                        setParentId(folder._id);
                      }}
                    >
                      Upload Documents
                    </Dropdown.Item>
                  )}
                  {canModifyDocument && (
                    <Dropdown.Item
                      onClick={(e) => handleRenameFolder(e, folder)}
                    >
                      Rename
                    </Dropdown.Item>
                  )}

                  {canDeleteDocument && (
                    <Dropdown.Item
                      onClick={(e) => handleDelete(e, folder._id, null)}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                  {/* {canModifyDocument && (
                    <Dropdown.Item onClick={handleUserAccess}>
                      User Access
                    </Dropdown.Item>
                  )} */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {folder.documents && visibilityMap[folder.name] && !isFirstLevel && (
            <ul className="list-1">
              {folder.documents.map((doc) => (
                <li key={doc._id}>
                  <div className="d-flex align-items-center justify-content-between list-item">
                    <Link
                      onClick={(e) => handleViewDocument(e, doc.documentLink)}
                    >
                      {doc.name}
                      {isNewDocument(doc?.created_at) && (
                        <span
                          className="ms-1 text-primary text-uppercase fw-bold"
                          style={{ fontSize: "8px" }}
                        >
                          New
                        </span>
                      )}
                    </Link>
                    <Dropdown>
                      <Dropdown.Toggle
                        align="end"
                        variant="transparent text-body no-arrow-dropdown p-0 border-0"
                        style={{ minHeight: "auto" }}
                      >
                        <FeatherIcon icon="more-vertical" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => handleDownload(e, doc.documentLink)}
                        >
                          Download
                        </Dropdown.Item>
                        {canModifyDocument && (
                          <Dropdown.Item
                            onClick={() =>
                              handleUpdateDocumentModal(folder._id, doc)
                            }
                          >
                            Rename
                          </Dropdown.Item>
                        )}
                        {canDeleteDocument && (
                          <Dropdown.Item
                            onClick={(e) =>
                              handleDelete(e, folder?._id, doc?._id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                        {/* {canModifyDocument && (
                          <Dropdown.Item onClick={handleUserAccess}>
                            User Access
                          </Dropdown.Item>
                        )} */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {folder.subfolders && (
            <ul className="list-1">
              {renderFoldersAndDocuments(folder.subfolders, false)}
            </ul>
          )}
        </Card.Body>
      </Card>
    ));
  };

  return (
    <div>
      <div className="page-heading d-flex align-items-top gap-3">
        {id && (
          <div onClick={goBack}>
            <Button variant="outline-light btn-icon">
              <FeatherIcon icon="chevron-left" />
            </Button>
          </div>
        )}
        <div>
          <h2 className="m-0">Documents Center</h2>
        </div>
      </div>

      <Row>
        <Col xs="12">
          <Row className="filter align-items-center justify-content-between gap-3 mb-3">
            <Col sm="6" xl="4" className="order-2 order-sm-1">
              {/* <Form onSubmit={handleSearch}>
                <div className="icon-input document-input">
                  <Form.Control
                    type="search"
                    placeholder="Search keyword"
                    value={searchInput}
                    onChange={handleSearchChange}
                  />
                  <Button
                    variant="none text-body"
                    type="submit"
                    className="px-0"
                  >
                    <FeatherIcon icon="search" style={{ cursor: "pointer" }} />
                  </Button>
                </div>
              </Form> */}
            </Col>

            {canAddDocument && (
              <Col sm="auto" className="order-1 order-sm-2">
                <div className="d-flex justify-content-end flex-wrap gap-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      align="end"
                      variant="primary no-arrow-dropdown btn-100"
                      onClick={() => {
                        setFolderData(null);
                        setParentId(null); // No parent ID for main folder
                        setShowCreateFolderModal(true);
                      }}
                    >
                      <FeatherIcon icon="plus" />
                      Create Folder
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </Col>
            )}
          </Row>
        </Col>

        <Col xs="12">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 575: 1, 900: 3 }}
          >
            <Masonry gutter="var(--spacing2)">
              {renderFoldersAndDocuments(foldersfilesData?.data, true)}
            </Masonry>
          </ResponsiveMasonry>
        </Col>
      </Row>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleClose}
        handleDelete={
          deleteTarget?.documentId ? handleDeleteFile : handleDeleteFolder
        }
        isLoading={
          isDeleting ||
          deleteIsLoading ||
          deleteFolderIsLoading ||
          deleteFileIsLoading
        }
      />

      <UserAccessModal
        show={showUserAccessModal}
        handleClose={closeUserAccessModal}
        icon={IMAGES.IconFile}
      />

      <CreateFolderModal
        show={showCreateFolderModal}
        handleClose={() => setShowCreateFolderModal(false)}
        parentId={parentId}
        folderData={folderData}
        useCreateFolderMutation={useCreateFolderMutation}
        useUpdateFolderMutation={useUpdateFolderMutation}
      />

      <DocumentUploadModal
        show={showModal}
        handleClose={handleModal}
        id={parentId}
        setIsUploadingParent={setIsUploading}
        useCreateDocumentMutation={useCreateDocumentMutation}
      />

      <UpdateDocumentModal
        show={showUpdateDocumentModal}
        handleClose={closeUpdateDocumentModal}
        documentData={documentToUpdate}
        useUpdateDocumentMutation={useUpdateDocumentMutation}
      />

      <Modal show={showDocModal} onHide={resetDocumentState} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {fileType === "pdf" ? (
            <div>
              <canvas
                ref={pdfCanvasRef}
                style={{ width: "100%", height: "auto" }}
              ></canvas>
              <div className="d-flex justify-content-between mt-2">
                <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                  Previous
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : fileType === "mp4" ? (
            <video controls style={{ width: "100%", height: "100%" }}>
              <source src={docUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : ["png", "jpg", "jpeg", "gif", "svg"].includes(fileType) ? (
            <Image src={docUrl} className="w-100" alt="Document Preview" />
          ) : ["xls", "xlsx", "ppt", "pptx", "doc", "docx"].includes(
              fileType
            ) ? (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                docUrl
              )}`}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Document Preview"
            />
          ) : (
            <p>File type not supported for preview.</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DocumentsCenter;
