import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import BrochuresModal from "./brochuresModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetBrochuresQuery,
  useUpdateGalleryMutation,
  useCreateGalleryMutation,
  useDeleteGalleryMutation
} from "../../../../services/dashboard";
import { useUploadFileMutation } from "../../../../services/upload";

import { usersApi } from "../../../../services/user";

import { checkPermissions } from "../../../../utils/checkPermissions";

const Brochure = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    message: ""
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);

  const { data, isLoading } = useGetBrochuresQuery(undefined);
  const [updateGallery, { isLoading: isUpdating }] = useUpdateGalleryMutation();
  const [createGallery, { isLoading: isCreating }] = useCreateGalleryMutation();
  const [deleteGallery, { isLoading: isDeleting, refetch }] =
    useDeleteGalleryMutation();

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (selectedClassId) {
      const selectedClass = data?.data.find(
        (item) => item._id === selectedClassId
      );
      if (selectedClass) {
        setFormData({
          image: selectedClass.image,
          name: selectedClass.name,
          message: selectedClass.message
        });
        setImagePreviewUrl(selectedClass.image);
      }
    } else {
      setFormData({ image: "", name: "", message: "" });
      setImagePreviewUrl("");
    }
  }, [selectedClassId, data]);

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([
        { type: "SingleClass", id: selectedClassId }
      ])
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectedClassId(null);
    setFormData({ image: "", name: "", message: "" });
    setImagePreviewUrl("");
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files.length > 0) {
      const file = files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setFormData((prev) => ({
        ...prev,
        image: file
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsGalleryLoading(true);

    if (!formData.name || !formData.image) {
      Notification("All fields are required", "error");
      setIsGalleryLoading(false);
      return;
    }

    if (formData.image && typeof formData.image === "object") {
      try {
        const uploadResponse = await uploadFile(formData.image).unwrap();
        const imageLink = uploadResponse.data.blobUrl;

        const payload = {
          id: selectedClassId,
          body: {
            name: formData.name,
            image: imageLink,
            galleryType: "brochures"
          }
        };

        if (selectedClassId) {
          await updateGallery(payload);
          Notification("Brochure Updated successfully", "success");
        } else {
          await createGallery(payload.body);
          Notification("Brochure Added successfully", "success");
        }

        handleClose();
      } catch (error) {
        Notification("Error while submitting", "error");
        console.error("Error saving brochure:", error);
      } finally {
        setIsGalleryLoading(false);
      }
    } else {
      const payload = {
        id: selectedClassId,
        body: {
          name: formData.name,
          image: formData.image,
          galleryType: "brochures"
        }
      };

      try {
        if (selectedClassId) {
          await updateGallery(payload);
          Notification("Brochure Updated successfully", "success");
        } else {
          await createGallery(payload.body);
          Notification("Brochure Added successfully", "success");
        }

        handleClose();
      } catch (error) {
        Notification("Error while submitting", "error");
        console.error("Error saving brochure:", error);
      } finally {
        setIsGalleryLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteGallery(selectedClassId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete Brochure:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedClassId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Brochures", dataField: "image", widthClass: "w-50" },
    { label: "Title", dataField: "name", widthClass: "w-50" }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedClassId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />

      <BrochuresModal
        show={show}
        handleClose={handleClose}
        selectedClassId={selectedClassId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        imagePreviewUrl={imagePreviewUrl}
        formData={formData}
        isUpdating={isGalleryLoading}
        isCreating={isGalleryLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedClassId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default Brochure;
