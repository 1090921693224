import { useState } from 'react';
import { Form,Card,Image,Button } from 'react-bootstrap';
import IMAGES from '../../assets/images';
import UploadFile from '../../assets/plugins/react-drag-drop-upload';
import { MultiSelect } from "react-multi-select-component";

function CreateUserClass() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const options = [
    { label: "User Admin Setting", value: "User Admin Setting" },
    { label: "Upload Document", value: "Internal" },
    { label: "Edit/Upload Guides", value: "Admin" },
  ];
  const [selected, PermissionsLevels] = useState([]);

  return (
    <div>
      <h2 className='page-heading'>Admin Settings</h2>
      
      <Card className='section-card'>
        <div className='section-card-heading'>
          <h4 className='title'><Image src={IMAGES.IconUpload} alt=''/>Upload Documents</h4>
        </div>
        <Form>
          <Form.Group className="form-group">
            <UploadFile/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Permissions Levels</Form.Label>
            <MultiSelect
              options={options}
              value={selected}
              onChange={PermissionsLevels}
              labelledBy="Select"
            />
          </Form.Group>
          <div className='btn-action'>
              <Button variant="light">Cancel</Button>
              <Button variant="primary">Save</Button>
          </div>
        </Form>
      </Card>
  </div>
  );
}

export default CreateUserClass;
