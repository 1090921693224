import React, { useState } from "react";
import { Button, Form, Modal, Spinner, Image } from "react-bootstrap";

import DeleteModal from "../../../../components/Modals/DeleteItem";
import IMAGES from "../../../../assets/images";

const AlertModal = ({
  show,
  handleClose,
  selectedAlertId,
  handleFormSubmit,
  handleFormChange,
  formData,
  isUpdating,
  isCreating,
  onDelete,
  isDeleting,
  id,
  canAdd,
  canModify,
  canDelete
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete();
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animation
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Image src={IMAGES.IconPlusCircle} alt="Add Alert" />
            {selectedAlertId ? "Edit Alert" : "Add Alert"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Body>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleFormChange}
                rows={4}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {canDelete && (
              <div className="d-flex gap-2">
                {id && (
                  <Button
                    onClick={handleDelete}
                    variant="danger"
                    disabled={isUpdating || isCreating}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}

            {(canAdd || canModify) && (
              <Button
                variant="primary"
                type="submit"
                disabled={isUpdating || isCreating}
              >
                {isUpdating || isCreating ? (
                  <Spinner color="light" size="sm" />
                ) : selectedAlertId ? (
                  "Save Changes"
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default AlertModal;
