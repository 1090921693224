import React, { useState, useEffect } from "react";
import { ReactDragDropUpload } from "react-drag-drop-upload";
import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import IMAGES from "../../../assets/images";

const DocumentUpload = ({ onFileSelect, imgLink }) => {
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState(imgLink);

  useEffect(() => {
    setImageUrl(imgLink);
  }, [imgLink]);

  const handleChange = (data) => {
    const newFiles = [...files, ...data];
    setFiles(newFiles);
    onFileSelect(newFiles);
    setImageUrl(null);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    onFileSelect(updatedFiles);
    setImageUrl(null);
  };

  const getFileIcon = (file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return IMAGES.IconPdf;
      case "doc":
      case "docx":
        return IMAGES.IconDoc;
      case "xls":
      case "xlsx":
        return IMAGES.IconXls;
      case "exe":
        return IMAGES.IconExe;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "svg":
        return IMAGES.IconImg;
      case "zip":
        return IMAGES.IconZip;
      default:
        return null;
    }
  };

  const truncateFileName = (name) => {
    if (name.length <= 10) return name;
    return name.substring(0, 10) + "...";
  };

  const renderPreview = () => {
    return files.map((file, index) => {
      const icon = getFileIcon(file);
      return (
        <div
          key={index}
          className="file-preview d-flex flex-column align-items-center m-2"
        >
          {icon ? (
            <img
              src={icon}
              className="object-fit-cover"
              alt="File icon"
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <img
              src={URL.createObjectURL(file)}
              alt="Uploaded file"
              className="object-fit-cover"
              style={{ width: "100px", height: "100px" }}
            />
          )}
          <span>{truncateFileName(file.name)}</span>
          <Button
            variant="danger remove-image-btn mt-2"
            onClick={() => handleRemoveFile(file)}
          >
            <FeatherIcon icon="trash-2" /> Delete
          </Button>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col items-center justify-center react-drag-drop-upload w-100">
      <div className="upload-area w-100" style={{ position: "relative" }}>
        <ReactDragDropUpload
          handleChange={handleChange}
          className="rounded text-center w-100"
          multiple={true}
          variant="small"
        />
      </div>
      {files.length > 0 && (
        <div className="preview-container d-flex align-items-center justify-content-start flex-row overflow-auto mt-3 w-100">
          {renderPreview()}
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;
