import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../components/Loader/ComponentLoader";
import UserTable from "../../components/Tables/ReusableTable";
import UserClassModal from "./Models/UserManagement/UserClassModal";
import { Notification } from "../../components/ToastNotifcaiton";

import {
  useGetRoleClassesQuery,
  useGetRolesQuery,
  useCreateClassMutation,
  useGetSingleClassQuery,
  useUpdateClassMutation,
  useDeleteClassMutation
} from "../../services/user";

import { usersApi } from "../../services/user";

import { checkPermissions } from "../../utils/checkPermissions";

const UserClassManagementTab = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);

  const [deleteClass, { isLoading: deleteIsLoading }] =
    useDeleteClassMutation();

  const handleDelete = async (id) => {
    try {
      await deleteClass(selectedClassId).unwrap();
      Notification("Role Class deleted successfully", "success");
      refetch(); // Optionally refetch the list after a delete
    } catch (error) {
      Notification("Failed to delete role class", "error");
      console.error("Failed to delete role class:", error);
    }
  };

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([
        { type: "SingleClass", id: selectedClassId }
      ])
    );
  };
  const handleClose = () => {
    if (selectedClassId) {
      // Make sure there's an ID to invalidate
      dispatch(
        usersApi.util.invalidateTags([
          { type: "SingleClass", id: selectedClassId }
        ])
      );
    }
    setShow(false);
    setSelectedClassId(null);
  };

  const { data, isLoading, refetch } = useGetRoleClassesQuery();
  const { data: rolesData, isLoading: rolesIsLoading } = useGetRolesQuery();
  const [createClass, { isLoading: isClassCreating }] =
    useCreateClassMutation();
  const [updateClass, { isLoading: isClassUpdating }] =
    useUpdateClassMutation();
  const { data: singleClass, isLoading: isLoadingSingleClass } =
    useGetSingleClassQuery(selectedClassId, {
      skip: !selectedClassId
    });

  if (
    isLoading ||
    rolesIsLoading ||
    (selectedClassId && isLoadingSingleClass)
  ) {
    return <ComponentLoader />;
  }

  const handleSave = async (classData) => {
    try {
      if (selectedClassId) {
        await updateClass({ id: selectedClassId, body: classData }).unwrap();
        Notification("User class updated successfully", "success");
      } else {
        await createClass(classData).unwrap();
        Notification("User class created successfully", "success");
      }
      handleClose();
    } catch (error) {
      Notification("Failed to save User class", "error");
      console.error("Failed to save User class:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedClassId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Class", dataField: "name", widthClass: "w-25" },
    { label: "Permissions", dataField: "roles", widthClass: "w-50" }
  ];

  const canAddRole = checkPermissions(user, "add_roles");

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1">
          {/* WILL ENABLE LATER */}
          {/* <div className="icon-input">
            <Form.Control type="search" placeholder="Search keyword" />
            <FeatherIcon icon="search" />
          </div> */}
        </Col>
        {canAddRole && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedClassId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Create User Class
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <UserClassModal
        show={show}
        handleClose={handleClose}
        rolesData={rolesData?.data}
        onSave={handleSave}
        isLoading={isClassCreating || isClassUpdating}
        classData={singleClass}
        isEdit={!!selectedClassId}
        selectedClassId={selectedClassId}
        onDelete={handleDelete}
      />
      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />
    </div>
  );
};

export default UserClassManagementTab;
