import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import IMAGES from "../../assets/images";

const AttachmentUploader = ({ attachments, setAttachments }) => {
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newAttachments = files.map((file) => ({
      file,
      name: file.name,
      ext: file.name.split(".").pop().toLowerCase()
    }));
    setAttachments((prev) => [...prev, ...newAttachments]);
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const getFileIcon = (attachment) => {
    const { ext, file } = attachment;
    switch (ext) {
      case "pdf":
        return IMAGES.IconPdf;
      case "doc":
      case "docx":
        return IMAGES.IconDoc;
      case "xls":
      case "xlsx":
        return IMAGES.IconXls;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "svg":
        return file ? URL.createObjectURL(file) : attachment.link;
      default:
        return IMAGES.IconOther;
    }
  };

  const truncateFileName = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
    const truncatedName =
      nameWithoutExtension.length > 15
        ? nameWithoutExtension.slice(0, 15) + "..."
        : nameWithoutExtension;
    return `${truncatedName}.${extension}`;
  };

  return (
    <div className="attachment-uploader">
      <Form.Group controlId="formAttachments">
        <Form.Label>Attachments</Form.Label>
        <Form.Control
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="fileInput"
        />
        <div className="d-flex mt-3 gap-3 flex-wrap">
          {attachments.map((attachment, index) => (
            <div key={index} className="position-relative text-center">
              <img
                src={getFileIcon(attachment)}
                alt="Attachment"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "contain" // Ensure aspect ratio is maintained
                }}
              />
              <div
                className="position-absolute top-0 end-0"
                onClick={() => handleRemoveAttachment(index)}
                style={{ cursor: "pointer" }}
              >
                <AiOutlineCloseCircle size={18} color="red" />
              </div>
              <div style={{ wordBreak: "break-word" }}>
                {truncateFileName(attachment.name)}
              </div>
            </div>
          ))}
        </div>
        <Button
          variant="secondary"
          className="mt-2"
          onClick={() => document.getElementById("fileInput").click()}
        >
          Add Attachments
        </Button>
      </Form.Group>
    </div>
  );
};

export default AttachmentUploader;
