export const extractSummary = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Select all heading tags from h1 to h6
  const headings = Array.from(
    doc.querySelectorAll("h1, h2, h3, h4, h5, h6")
  ).map((h) => h.textContent);
  const listItems = Array.from(doc.querySelectorAll("li")).map(
    (li) => li.textContent
  );
  const paragraphs = Array.from(doc.querySelectorAll("p")).map(
    (p) => p.textContent
  );

  // Create a short summary
  let summary = "";
  if (headings.length > 0) {
    summary += `${headings.join(", ")}. `;
  }
  if (listItems.length > 0) {
    summary += `${listItems.slice(0, 3).join(", ")}. `;
  }
  if (paragraphs.length > 0) {
    summary += `${paragraphs.slice(0, 1).join(" ")}.`;
  }

  // If no content is found, provide a default message
  if (summary === "") {
    summary = "No summary available.";
  }

  return summary.trim();
};
