import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal, Image, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Notification } from "../../../components/ToastNotifcaiton";
import IMAGES from "../../../assets/images";

const ChangePasswordModal = ({
  show,
  handleClose,
  updatePasswordFunc,
  updatePasswordLoading,
  type,
  id,
  resetPasswordFun,
  resetPasswordLoading
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false
  });

  const [resetUserPassword, setResetUserPassword] = useState(false);

  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleResetUserPasswordChange = (event) => {
    setResetUserPassword(event.target.checked);
    if (event.target.checked) {
      setFormData((prev) => ({
        ...prev,
        newPassword: "",
        confirmPassword: ""
      }));
    }
  };

  // const handleSubmit = async () => {
  //   if (
  //     type === "user-profile" &&
  //     (!formData.newPassword || !formData.confirmPassword)
  //   ) {
  //     Notification("New and confirm password fields are required", "warning");
  //     return;
  //   } else if (
  //     type !== "user-profile" &&
  //     (!formData.oldPassword ||
  //       !formData.newPassword ||
  //       !formData.confirmPassword)
  //   ) {
  //     Notification("All fields are required", "warning");
  //     return;
  //   }

  //   if (!passwordRegex.test(formData.newPassword)) {
  //     Notification("New Password must meet the requirements.", "error");
  //     return;
  //   }

  //   if (formData.newPassword !== formData.confirmPassword) {
  //     Notification("Passwords do not match", "error");
  //     return;
  //   }

  //   const body = {
  //     newPassword: formData.newPassword,
  //     confirmPassword: formData.confirmPassword,
  //     oldPassword: formData.oldPassword
  //   };

  //   const payload =
  //     type === "user-profile" && id
  //       ? {
  //           id,
  //           password: formData.newPassword
  //         }
  //       : { body };

  //   try {
  //     await updatePasswordFunc(payload).unwrap();
  //     Notification("Password updated successfully", "success");
  //     handleClose();
  //     setFormData({
  //       oldPassword: "",
  //       newPassword: "",
  //       confirmPassword: ""
  //     });
  //   } catch (error) {
  //     if (type !== "user-profile")
  //       Notification(
  //         error?.data?.error || "Failed to update password",
  //         "error"
  //       );
  //   }
  // };

  const handleSubmit = async () => {
    if (resetUserPassword) {
      try {
        await resetPasswordFun({ id }).unwrap();
        Notification("Temporary password sent to user successfully", "success");
        handleClose();
        setResetUserPassword(false);
      } catch (error) {
        Notification(
          error?.data?.error || "Failed to send temporary password",
          "error"
        );
      }
    } else {
      if (
        type === "user-profile" &&
        (!formData.newPassword || !formData.confirmPassword)
      ) {
        Notification("New and confirm password fields are required", "warning");
        return;
      } else if (
        type !== "user-profile" &&
        (!formData.oldPassword ||
          !formData.newPassword ||
          !formData.confirmPassword)
      ) {
        Notification("All fields are required", "warning");
        return;
      }

      if (!passwordRegex.test(formData.newPassword)) {
        Notification("New Password must meet the requirements.", "error");
        return;
      }

      if (formData.newPassword !== formData.confirmPassword) {
        Notification("Passwords do not match", "error");
        return;
      }

      const body = {
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
        oldPassword: formData.oldPassword
      };

      const payload =
        type === "user-profile" && id
          ? {
              id,
              password: formData.newPassword
            }
          : { body };

      try {
        await updatePasswordFunc(payload).unwrap();
        Notification("Password updated successfully", "success");
        handleClose();
        setFormData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        });
      } catch (error) {
        if (type !== "user-profile")
          Notification(
            error?.data?.error || "Failed to update password",
            "error"
          );
      }
    }
  };

  const fieldsToShow =
    type === "user-profile"
      ? ["newPassword", "confirmPassword"]
      : ["oldPassword", "newPassword", "confirmPassword"];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Image src={IMAGES.IconLock} alt="" />
          {type === "user-profile" ? "Reset Password" : "Update Password"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {fieldsToShow.map((key, idx) => (
            <Form.Group key={key} className="pb-3">
              <Form.Label>
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={showPasswords[key] ? "text" : "password"}
                  placeholder={`Enter ${key
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}`}
                  value={formData[key]}
                  onChange={handleInputChange}
                  name={key}
                  disabled={resetUserPassword}
                />
                <span
                  onClick={() => togglePasswordVisibility(key)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                >
                  {showPasswords[key] ? (
                    <FeatherIcon icon="eye-off" />
                  ) : (
                    <FeatherIcon icon="eye" />
                  )}
                </span>
              </div>
              {key === "newPassword" && (
                <Form.Text className="text-muted">
                  Password must be at least 8 characters long, include at least
                  one uppercase letter, one number, and one symbol.
                </Form.Text>
              )}
            </Form.Group>
          ))}
          {type === "user-profile" && (
            <Form.Group className="pb-3">
              <Form.Check
                type="checkbox"
                label="Send temporary password to user"
                checked={resetUserPassword}
                onChange={handleResetUserPasswordChange}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={updatePasswordLoading}
          variant="light"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={updatePasswordLoading}
          variant="primary"
          onClick={handleSubmit}
        >
          {updatePasswordLoading || resetPasswordLoading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : type === "user-profile" ? (
            "Reset Password"
          ) : (
            "Update Password"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
