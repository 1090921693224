import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import NewsModal from "./newsModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetNewsQuery,
  useUpdateNewsMutation,
  useCreateNewsMutation,
  useDeleteNewsMutation
} from "../../../../services/dashboard";
import { useUploadFileMutation } from "../../../../services/upload";

import { usersApi } from "../../../../services/user";
import { checkPermissions } from "../../../../utils/checkPermissions";

const News = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);
  const [show, setShow] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [formData, setFormData] = useState({
    images: [],
    title: "",
    description: ""
  });
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const { data, isLoading } = useGetNewsQuery(undefined);
  const [updateNews, { isLoading: isUpdating }] = useUpdateNewsMutation();
  const [createNews, { isLoading: isCreating }] = useCreateNewsMutation();
  const [deleteNews, { isLoading: isDeleting, refetch }] =
    useDeleteNewsMutation();
  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (selectedNewsId) {
      const selectedNews = data?.data?.find(
        (item) => item._id === selectedNewsId
      );
      if (selectedNews) {
        setFormData({
          images: selectedNews.images,
          title: selectedNews.title,
          description: selectedNews.description
        });
        setImagePreviewUrls(selectedNews.images);
      }
    } else {
      setFormData({ images: [], title: "", description: "" });
      setImagePreviewUrls([]);
    }
  }, [selectedNewsId, data]);

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([{ type: "SingleNews", id: selectedNewsId }])
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectedNewsId(null);
    setFormData({ images: [], title: "", description: "" });
    setImagePreviewUrls([]);
  };

  const handleFormChange = (e) => {
    const { name, files } = e.target;
    if (name === "images" && files.length) {
      const newFiles = Array.from(files);
      const newImageUrls = newFiles.map((file) => URL.createObjectURL(file));
      setImagePreviewUrls((prev) => [...prev, ...newImageUrls]);
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...newFiles]
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: e.target.value
      }));
    }
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, idx) => idx !== index)
    }));
    setImagePreviewUrls((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsNewsLoading(true);
    if (!formData.title) {
      Notification("All fields are required", "error");
      setIsNewsLoading(false);
      return;
    }

    const uploadedImageLinks = await Promise.all(
      formData.images.map(async (image) => {
        if (typeof image === "string") return image;
        const uploadResponse = await uploadFile(image).unwrap();
        return uploadResponse.data.blobUrl;
      })
    );

    const payload = {
      id: selectedNewsId,
      body: {
        title: formData.title,
        description: formData.description,
        images: uploadedImageLinks
      }
    };

    try {
      if (selectedNewsId) {
        await updateNews(payload);
        Notification("News Updated successfully", "success");
      } else {
        await createNews(payload.body);
        Notification("News Added successfully", "success");
      }
      handleClose();
    } catch (error) {
      Notification("Error while submitting", "error");
      console.error("Error saving news:", error);
    } finally {
      setIsNewsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteNews(selectedNewsId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete News:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedNewsId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Image", dataField: "firstImage", widthClass: "w-15" },
    { label: "Title", dataField: "title", widthClass: "w-15" },
    { label: "Description", dataField: "description", widthClass: "w-50" }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) return <ComponentLoader />;

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedNewsId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />

      <NewsModal
        show={show}
        handleClose={handleClose}
        selectedNewsId={selectedNewsId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        imagePreviewUrls={imagePreviewUrls}
        handleRemoveImage={handleRemoveImage}
        formData={formData}
        isUpdating={isNewsLoading}
        isCreating={isNewsLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedNewsId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default News;
