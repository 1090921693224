import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { Row, Col, Button, Pagination } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../components/Loader/ComponentLoader";
import UpdatesTable from "../../components/Tables/UpdatesTable";

import { useGetUpdatesQuery } from "../../services/updates";

import { checkPermissions } from "../../utils/checkPermissions";
import { extractSummary } from "../../utils/tables";

const Updates = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.profile.user);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const { data, isLoading } = useGetUpdatesQuery({
    page: currentPage,
    limit
  });

  const handleRowClick = (data) => {
    navigate(`/update-detail/${data?._id}`);
  };

  const summarizedData = data?.data.map((item) => ({
    ...item,
    summary: extractSummary(item.content),
    createdAt: moment(item.updatedAt).format("MMMM DD YYYY [at] hh:mm A")
  }));

  const headers = [
    { label: "", dataField: "image" },
    { label: "Title", dataField: "title" },
    { label: "Date", dataField: "createdAt" },
    { label: "Summary", dataField: "summary" }
  ];

  const canAdd = checkPermissions(user, "add_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  // Calculate the visible page range
  const totalPages = data?.pagination?.totalPages || 1;
  const visiblePages = [];
  let startPage = Math.max(currentPage - 1, 1);
  let endPage = Math.min(startPage + 2, totalPages);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Link to={"/update"}>
                <Button variant="primary">
                  <FeatherIcon icon="plus" />
                  Add New Update
                </Button>
              </Link>
            </div>
          </Col>
        )}
      </Row>

      <UpdatesTable
        headers={headers}
        data={summarizedData}
        handleRowClick={handleRowClick}
      />

      {/* Pagination Controls */}
      <Pagination className="justify-content-center mt-4">
        <Pagination.First
          onClick={() => setCurrentPage(1)}
          disabled={!data?.pagination?.isPrevious}
        />
        <Pagination.Prev
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={!data?.pagination?.isPrevious}
        />
        {visiblePages.map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={!data?.pagination?.isNext}
        />
        <Pagination.Last
          onClick={() => setCurrentPage(totalPages)}
          disabled={!data?.pagination?.isNext}
        />
      </Pagination>
    </div>
  );
};

export default Updates;
