import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import ComponentLoader from "../../components/Loader/ComponentLoader";
import { Notification } from "../../components/ToastNotifcaiton";
import ListCard from "../../components/Cards/ListCard";

import IMAGES from "../../assets/images";

import {
  useGetGuidesQuery,
  useDeleteGuideMutation,
  useUserAccessGuideMutation
} from "../../services/guides";

import { checkPermissions } from "../../utils/checkPermissions";

const Guides = () => {
  const location = useLocation();
  const user = useSelector((state) => state.profile.user);

  const [searchInput, setSearchInput] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");

  const { data, isLoading, isFetching, refetch } = useGetGuidesQuery({
    name: submittedSearch
  });

  const [updateGuideAccess, { isLoading: isGuideAccessUpdating }] =
    useUserAccessGuideMutation();

  const [deleteGuide, { isLoading: deleteIsLoading }] =
    useDeleteGuideMutation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchName = params.get("search");
    if (searchName) {
      setSearchInput(searchName);
      setSubmittedSearch(searchName);
    }
  }, [location.search]);

  const handleDelete = async (id) => {
    try {
      await deleteGuide(id).unwrap();
      Notification("Guide deleted successfully", "success");
    } catch (error) {
      Notification("Failed to delete guide", "error");
      console.error("Failed to delete guide:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    // Automatically set the submittedSearch when input is cleared
    if (e.target.value === "") {
      setSubmittedSearch("");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSubmittedSearch(searchInput);
  };

  const handleUpdateAccessGuide = async (id, accessData) => {
    try {
      await updateGuideAccess({ id, classes: accessData }).unwrap();
      Notification("Guide Access Update successfully", "success");
      refetch();
    } catch (error) {
      Notification("Failed to Update Guide Access", "error");
      console.error("Failed to Update Guide Access:", error);
    }
  };

  const canAddGuide = checkPermissions(user, "add_guide");

  if (isLoading || isFetching) {
    return <ComponentLoader />;
  }

  if (!data?.data?.length) {
    return (
      <Row className="align-items-center">
        <Col xs="12">
          <Row className="filter align-items-center justify-content-between mb-5">
            <Col sm="6" xl="4" className="order-2 order-sm-1">
              <Form onSubmit={handleSearch}>
                <div className="icon-input document-input ">
                  <Form.Control
                    type="search"
                    placeholder="Search keyword"
                    value={searchInput}
                    onChange={handleSearchChange}
                  />
                  <Button
                    variant="none text-body"
                    type="submit"
                    className="px-0"
                  >
                    <FeatherIcon icon="search" style={{ cursor: "pointer" }} />
                  </Button>
                </div>
              </Form>
            </Col>
            {canAddGuide && (
              <Col sm="auto" className="order-1 order-sm-2">
                <div className="d-flex justify-content-end flex-wrap gap-2">
                  <Link to={"/guide"}>
                    <Button variant="primary">
                      <FeatherIcon icon="plus" />
                      Add New Guide
                    </Button>
                  </Link>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={12} className="text-center mb-3">
          <div>
            <Image
              src={IMAGES.IconGuide}
              className="icon-lg mb-2"
              alt="Folder"
            />
            <p className="fw-bold">No Guide found!</p>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <h2 className="page-heading">Guides</h2>
      <Row>
        <Col xs="12">
          <Row className="filter align-items-center justify-content-between gap-3 mb-3">
            <Col sm="6" xl="4" className="order-2 order-sm-1">
              <Form onSubmit={handleSearch}>
                <div className="icon-input document-input ">
                  <Form.Control
                    type="search"
                    placeholder="Search keyword"
                    value={searchInput}
                    onChange={handleSearchChange}
                  />
                  <Button
                    variant="none text-body"
                    type="submit"
                    className="px-0"
                  >
                    <FeatherIcon icon="search" style={{ cursor: "pointer" }} />
                  </Button>
                </div>
              </Form>
            </Col>
            {canAddGuide && (
              <Col sm="auto" className="order-1 order-sm-2">
                <div className="d-flex justify-content-end flex-wrap gap-2">
                  <Link to={"/guide"}>
                    <Button variant="primary">
                      <FeatherIcon icon="plus" />
                      Add New Guide
                    </Button>
                  </Link>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        {data?.data.map((guide) => (
          <Col xs="12" key={guide._id}>
            <ListCard
              name={guide.name}
              img={guide.imageLink}
              link={`/guide-detail/${guide._id}`}
              editLink={`/guide/${guide._id}`}
              date={guide.createdAt}
              Id={guide._id}
              onDelete={handleDelete}
              isLoading={deleteIsLoading}
              updateAccess={handleUpdateAccessGuide}
              accessList={guide?.allowedClasses}
              isAccessLoading={isGuideAccessUpdating}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Guides;
