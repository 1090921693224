import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import IMAGES from "../assets/images";

function Footer() {
  return (
    <footer className="d-flex align-items-center bg-white text-center">
      <Container fluid className="px-3">
        <Row className="align-items-center justify-content-between gap-2 py-3 py-sm-0">
          <Col sm="auto" className="order-sm-2">
            <Link to="https://cygnuspay.com/">
              <Image
                src={IMAGES.Logo1}
                alt="Logo"
                style={{ height: "35px" }}
              />
            </Link>
          </Col>
          <Col sm="auto">
            <p className="mb-0 small">
                © {new Date().getFullYear()}{" "}
                <span className="fw-bold">Cygnus Payments</span>. All rights
                reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
