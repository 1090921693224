import React, { useState, useRef } from "react";
import {
  Card,
  CardBody,
  Form,
  FloatingLabel,
  Image,
  Button,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import IMAGES from "../../assets/images";
import {
  useGetUserPollsQuery,
  useSubmitPollMutation
} from "../../services/polls";

import { checkPermissions } from "../../utils/checkPermissions";
import { useSelector } from "react-redux";

const Poll = () => {
  const user = useSelector((state) => state.profile.user);
  const { data: pollsData, isLoading: isPollsLoading } = useGetUserPollsQuery(
    {}
  );
  const [submitPoll] = useSubmitPollMutation();
  const [selectedOption, setSelectedOption] = useState({});
  const [additionalAnswers, setAdditionalAnswers] = useState({});
  const [ratings, setRatings] = useState({});
  const swiperRef = useRef(null);

  const handleOptionChange = (pollId, optionId) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      [pollId]: optionId
    }));
  };

  const handleAdditionalAnswerChange = (questionId, answer) => {
    setAdditionalAnswers((prevState) => ({
      ...prevState,
      [questionId]: answer
    }));
  };

  const handleRatingChange = (ratingId, value) => {
    setRatings((prevState) => ({
      ...prevState,
      [ratingId]: value
    }));
  };

  const handleSubmit = async (pollId) => {
    const payload = {
      _id: pollId,
      optionId: selectedOption[pollId],
      additionalAnswers: Object.entries(additionalAnswers).map(
        ([questionId, answer]) => ({
          questionId,
          answer
        })
      ),
      ratings: Object.entries(ratings).map(([ratingId, value]) => ({
        questionId: ratingId,
        value
      }))
    };

    try {
      await submitPoll(payload);
      alert("Poll submitted successfully");
    } catch (error) {
      console.error("Failed to submit poll:", error);
    }
  };

  if (isPollsLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!pollsData || !pollsData.success) {
    return <div>Error loading polls</div>;
  }

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (pollsData.data.length === 0) {
    return (
      <Card className="primary-light">
        <CardBody className="d-flex">
          <h5 className="my-0">No Poll</h5>
          {(canAdd || canModify || canDelete) && (
            <Link
              to="dashboard/settings/polls"
              className="edit-link"
              style={{ marginLeft: "10px" }}
            >
              <PencilSquareIcon className="icon-xs" />
            </Link>
          )}
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next"
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {pollsData.data.map((poll, index) => (
          <SwiperSlide key={poll._id}>
            <Card className="primary-light" style={{ height: "400px" }}>
              <Card.Title className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <Image src={IMAGES.IconPoll} className="icon-sm" alt="" />
                  Poll
                  {(canAdd || canModify || canDelete) && (
                    <Link to="dashboard/settings/alerts" className="edit-link">
                      <PencilSquareIcon className="icon-xs" />
                    </Link>
                  )}
                </div>
                {pollsData.data.length > 1 && (
                  <div className="swiper-nav-btns">
                    <button
                      className="swiper-button-prev"
                      onClick={() => swiperRef.current.slidePrev()}
                    >
                      <ChevronLeftIcon className="icon-xs" />
                    </button>
                    <button
                      className="swiper-button-next"
                      onClick={() => swiperRef.current.slideNext()}
                    >
                      <ChevronRightIcon className="icon-xs" />
                    </button>
                  </div>
                )}
              </Card.Title>
              <CardBody className="d-flex flex-column">
                <div className="flex-grow-1">
                  <h5 className="my-0">{poll.question}</h5>
                  <Row className="gap-3 my-3">
                    {poll.options.map((option) => (
                      <Col key={option._id} sm={6} md={3}>
                        <Form.Check
                          type="radio"
                          name={`group${index}`}
                          id={option._id}
                          className=""
                          label={option.text}
                          onChange={() =>
                            handleOptionChange(poll._id, option._id)
                          }
                        />
                      </Col>
                    ))}
                  </Row>

                  {poll.additionalQuestions.length > 0 && (
                    <div className="mb-3">
                      {poll.additionalQuestions.map((additionalQuestion) => (
                        <Form.Group key={additionalQuestion._id}>
                          <FloatingLabel
                            controlId={`floatingTextarea${additionalQuestion._id}`}
                            label={additionalQuestion.question}
                            className="mb-3 form-label"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              onChange={(e) =>
                                handleAdditionalAnswerChange(
                                  additionalQuestion._id,
                                  e.target.value
                                )
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ))}
                    </div>
                  )}

                  {poll.ratings.length > 0 && (
                    <div className="mb-4">
                      {poll.ratings.map((rating) => (
                        <Form.Group key={rating._id}>
                          <h6 className="mb-3">{rating.question}</h6>
                          <div className="d-flex gap-2 flex-wrap poll-rate">
                            {[...Array(11).keys()].map((num) => (
                              <Link
                                key={num}
                                className={`btn btn-icon ${
                                  ratings[rating._id] === num
                                    ? "btn-selected"
                                    : "btn-outline-light"
                                }`}
                                onClick={() =>
                                  handleRatingChange(rating._id, num)
                                }
                              >
                                {num}
                              </Link>
                            ))}
                          </div>
                        </Form.Group>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-auto d-flex justify-content-end gap-2">
                  <Button
                    variant="primary btn-100"
                    onClick={() => handleSubmit(poll._id)}
                  >
                    Submit
                  </Button>
                </div>
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Poll;
