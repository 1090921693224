import { baseApi } from "../baseApi";

export const guidesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGuides: builder.query({
      query: (searchParams) => {
        // Construct the query string based on the searchParams provided
        const queryString =
          searchParams && searchParams.name
            ? `?name=${encodeURIComponent(searchParams.name)}`
            : "";
        return {
          url: `guides/all${queryString}`,
          method: "GET"
        };
      },
      providesTags: ["guides"]
    }),

    getSingleGuide: builder.query({
      query: (id) => ({
        url: `guides/${id}`,
        method: "GET"
      }),
      providesTags: ["guides"]
    }),
    createGuide: builder.mutation({
      query: (body) => ({
        url: "guides/create",
        method: "POST",
        body: body
      }),
      invalidatesTags: ["guides"]
    }),
    deleteGuide: builder.mutation({
      query: (id) => ({
        url: `guides/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["guides"]
    }),
    updateGuide: builder.mutation({
      query: (payload) => ({
        url: `guides/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["guides"]
    }),
    userAccessGuide: builder.mutation({
      query: (formData) => ({
        url: `guides/updateacccess/${formData.id}`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["guides"]
    })
  })
});

export const {
  useGetGuidesQuery,
  useGetSingleGuideQuery,
  useCreateGuideMutation,
  useDeleteGuideMutation,
  useUpdateGuideMutation,
  useUserAccessGuideMutation
} = guidesApi;
