import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../components/Loader/ComponentLoader";
import UserTable from "../../components/Tables/ReusableTable";
import UserModal from "./Models/UserManagement/UserModel";

import {
  useGetUsersQuery,
  useCreateUserMutation,
  useGetRoleClassesQuery,
  useGetRoleClassesDataQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useResetUserPasswordMutation
} from "../../services/user";
import { Notification } from "../../components/ToastNotifcaiton";

import { checkPermissions } from "../../utils/checkPermissions";
import { useSelector } from "react-redux";

const UserManagementTab = () => {
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleUserEdit = (user) => {
    setSelectedUser(user);
    handleShow();
  };

  const handleNewUser = () => {
    setSelectedUser(null);
    handleShow();
  };

  const { data, isLoading, refetch } = useGetUsersQuery();
  // const { data: classesData, isLoading: isClassesLoading } =
  //   useGetRoleClassesQuery();
  const { data: classesData, isLoading: isClassesLoading } =
    useGetRoleClassesDataQuery();

  const [createUser, { isLoading: isUserCreating }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUserUpdating, isSuccess }] =
    useUpdateUserMutation();

  const [
    resetUserPassword,
    { isLoading: isUserResetPasswordLoading, isResetPasswordSuccess }
  ] = useResetUserPasswordMutation();

  const [deleteUser, { isLoading: deleteIsLoading }] = useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const handleSave = async (userData) => {
    try {
      await createUser(userData).unwrap();
      Notification("User created successfully", "success");
      handleClose();
    } catch (error) {
      Notification(error?.data?.error || "Failed to create user", "error");
      console.error("Failed to create user:", error);
    }
  };

  const handleUpdate = async (userData) => {
    try {
      await updateUser(userData).unwrap();
      Notification("User update successfully", "success");
      handleClose();
    } catch (error) {
      Notification(error?.data?.error || "Failed to update user", "error");
      console.error("Failed to update user:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(selectedUser._id).unwrap();
      Notification("User deleted successfully", "success");
      refetch();
    } catch (error) {
      Notification(error?.data?.error || "Failed to delete user", "error");
      console.error("Failed to delete user:", error);
    }
  };

  const headers = [
    { label: "Name", dataField: "firstName", widthClass: "w-25" },
    { label: "Email", dataField: "email", widthClass: "w-25" },
    { label: "Permissions", dataField: "roles", widthClass: "w-50" }
  ];

  if (isLoading || isClassesLoading) {
    return <ComponentLoader />;
  }

  const canAddUser = checkPermissions(user, "add_user");
  const canAddRole = checkPermissions(user, "view_roles");

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1">
          {/* <div className="icon-input">
            <Form.Control type="search" placeholder="Search keyword" />
            <FeatherIcon icon="search" />
          </div> */}
        </Col>
        {canAddUser && canAddRole && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button variant="primary" onClick={handleNewUser}>
                <FeatherIcon icon="plus" /> Create New User
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <UserModal
        show={show}
        handleClose={handleClose}
        onSave={handleSave}
        onUpdate={handleUpdate}
        isLoading={isUserCreating}
        userCreating={isUserCreating}
        classesData={classesData?.data}
        userData={selectedUser}
        onDelete={handleDelete}
        handleUpdate={updateUser}
        isUserUpdating={isUserUpdating}
        id={selectedUser?._id}
        updatePasswordSucces={isSuccess}
        resetPasswordFun={resetUserPassword}
        resetPasswordLoading={isUserResetPasswordLoading}
      />
      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleUserEdit}
      />
    </div>
  );
};

export default UserManagementTab;
