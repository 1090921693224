import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import SpotlightModal from "./spotlightModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetEmployeesQuery,
  useUpdateEmployeesMutation,
  useCreateEmployeesMutation,
  useDeleteEmployeesMutation
} from "../../../../services/dashboard";
import { useUploadFileMutation } from "../../../../services/upload";

import { usersApi } from "../../../../services/user";

import { checkPermissions } from "../../../../utils/checkPermissions";

const Spotlights = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedSpotlightId, setSelectedSpotlightId] = useState(null);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    position: "",
    message: ""
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isSpotlightLoading, setIsSpotlightLoading] = useState(false);

  const { data, isLoading } = useGetEmployeesQuery(undefined);
  const [updateSpotlight, { isLoading: isUpdating }] =
    useUpdateEmployeesMutation();
  const [createSpotlight, { isLoading: isCreating }] =
    useCreateEmployeesMutation();
  const [deleteSpotlight, { isLoading: isDeleting, refetch }] =
    useDeleteEmployeesMutation();

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (selectedSpotlightId) {
      const selectedSpotlight = data?.data.find(
        (item) => item._id === selectedSpotlightId
      );
      if (selectedSpotlight) {
        setFormData({
          image: selectedSpotlight.image,
          name: selectedSpotlight.name,
          position: selectedSpotlight.position,
          message: selectedSpotlight.message
        });
        setImagePreviewUrl(selectedSpotlight.image);
      }
    } else {
      setFormData({ image: "", name: "", position: "", message: "" });
      setImagePreviewUrl("");
    }
  }, [selectedSpotlightId, data]);

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([
        { type: "SingleSpotlight", id: selectedSpotlightId }
      ])
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectedSpotlightId(null);
    setFormData({ image: "", name: "", position: "", message: "" });
    setImagePreviewUrl("");
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files.length > 0) {
      const file = files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setFormData((prev) => ({
        ...prev,
        image: file
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSpotlightLoading(true);

    if (
      !formData.name ||
      !formData.position ||
      !formData.message ||
      !formData.image
    ) {
      Notification("All fields are required", "error");
      setIsSpotlightLoading(false);
      return;
    }

    if (formData.image && typeof formData.image === "object") {
      try {
        const uploadResponse = await uploadFile(formData.image).unwrap();
        const imageLink = uploadResponse.data.blobUrl;

        const payload = {
          id: selectedSpotlightId,
          body: {
            name: formData.name,
            position: formData.position,
            message: formData.message,
            image: imageLink
          }
        };

        if (selectedSpotlightId) {
          await updateSpotlight(payload);
          Notification("Spotlight Updated successfully", "success");
        } else {
          await createSpotlight(payload.body); // Create doesn't need id
          Notification("Spotlight Added successfully", "success");
        }

        handleClose();
      } catch (error) {
        Notification("Error while submitting", "error");
        console.error("Error saving spotlight:", error);
      } finally {
        setIsSpotlightLoading(false);
      }
    } else {
      const payload = {
        id: selectedSpotlightId,
        body: {
          name: formData.name,
          position: formData.position,
          message: formData.message,
          image: formData.image
        }
      };

      try {
        if (selectedSpotlightId) {
          await updateSpotlight(payload);
          Notification("Spotlight Updated successfully", "success");
        } else {
          await createSpotlight(payload.body); // Create doesn't need id
          Notification("Spotlight Added successfully", "success");
        }

        handleClose();
      } catch (error) {
        Notification("Error while submitting", "error");
        console.error("Error saving spotlight:", error);
      } finally {
        setIsSpotlightLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteSpotlight(selectedSpotlightId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete Spotlight:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedSpotlightId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Image", dataField: "image", widthClass: "w-15" },
    { label: "Name", dataField: "name", widthClass: "w-10" },
    { label: "Position", dataField: "position", widthClass: "w-15" },
    { label: "Message", dataField: "message", widthClass: "w-50" }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedSpotlightId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />

      <SpotlightModal
        show={show}
        handleClose={handleClose}
        selectedSpotlightId={selectedSpotlightId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        imagePreviewUrl={imagePreviewUrl}
        formData={formData}
        isUpdating={isSpotlightLoading}
        isCreating={isSpotlightLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedSpotlightId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default Spotlights;
