import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Image,
  Button,
  Spinner,
  Alert
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Notification } from "../../components/ToastNotifcaiton";
import IMAGES from "../../assets/images";

import { useChangePasswordMutation } from "../../services/auth";
import { useResetPasswordMutation } from "../../services/profile";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const [changePassword, { isLoading: isChangePasswordLoading }] =
    useChangePasswordMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] =
    useResetPasswordMutation();

  const [formFields, setFormFields] = useState({
    password: "",
    confirmPassword: ""
  });
  const [openNewEye, setOpenNewEye] = useState(false);
  const [openConfirmEye, setOpenConfirmEye] = useState(false);
  const [isLoading, setIsLoading] = useState(
    isChangePasswordLoading || isResetPasswordLoading
  );

  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleEyeOpen = (field) => {
    if (field === "password") {
      setOpenNewEye(!openNewEye);
    } else if (field === "confirmPassword") {
      setOpenConfirmEye(!openConfirmEye);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formFields;

    if (!passwordRegex.test(password)) {
      Notification(
        "Password must be at least 8 characters long, include at least one uppercase letter, one number, and one symbol.",
        "error"
      );
      return;
    }

    if (password !== confirmPassword) {
      Notification("Passwords do not match.", "error");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        id, // Pass the ID whether it exists or not; handled by the backend conditionally
        body: { password, confirmPassword }
      };
      const result = id
        ? await resetPassword(payload).unwrap()
        : await changePassword({
            email: location?.state?.email,
            password,
            confirmPassword
          }).unwrap();

      Notification("Password Changed Successfully", "success");
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      Notification(error?.data?.error || "Failed to change password", "error");
      console.error("Failed to change password:", error);
    }
  };

  return (
    <div
      className="riz-portal d-flex align-items-center justify-content-center w-100"
      style={{
        backgroundImage: `url(${IMAGES.login})`,
        backgroundColor: "var(--bs-primary)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col sm="10" md="8" lg="5">
            <Card className="card-login rounded-5 border-0">
              <Card.Body className="p-5">
                <Form onSubmit={handleSubmit}>
                  <div className="text-center mb-4">
                    <div className="d-flex align-items-center justify-content-center w-100 mb-5">
                      <Image
                        src={IMAGES.LogoLight}
                        alt="Logo"
                        className="logo"
                      />
                    </div>
                    <h4 className="text-white mb-3">Change Password</h4>
                  </div>
                  <Form.Group className="form-group">
                    <Form.Label className="text-white">New Password</Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={openNewEye ? "text" : "password"}
                        placeholder="New Password"
                        value={formFields.password}
                        onChange={handleChange}
                        name="password"
                      />
                      <span
                        onClick={() => handleEyeOpen("password")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                      >
                        {openConfirmEye ? (
                          <FeatherIcon icon="eye-off" />
                        ) : (
                          <FeatherIcon icon="eye" />
                        )}
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label className="text-white">
                      Confirm Password
                    </Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={openConfirmEye ? "text" : "password"}
                        placeholder="Confirm Password"
                        value={formFields.confirmPassword}
                        onChange={handleChange}
                        name="confirmPassword"
                      />
                      <span
                        onClick={() => handleEyeOpen("confirmPassword")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                      >
                        {openConfirmEye ? (
                          <FeatherIcon icon="eye-off" />
                        ) : (
                          <FeatherIcon icon="eye" />
                        )}
                      </span>
                    </div>
                  </Form.Group>
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                  <Link
                    to="/login"
                    className="text-white text-center mt-3 mb-0 d-block"
                  >
                    <FeatherIcon icon="arrow-left" className="me-2" />
                    Back to{" "}
                    <span className="fw-bold text-decoration-underline">
                      Login
                    </span>
                  </Link>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
