import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Notification = (message, type = "default") => {
  const toastOptions = {
    // position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  switch (type) {
    case "success":
      return toast.success(message, toastOptions);
    case "error":
      return toast.error(message, toastOptions);
    case "info":
      return toast.info(message, toastOptions);
    case "warn":
      return toast.warn(message, toastOptions);
    default:
      return toast(message, toastOptions);
  }
};
