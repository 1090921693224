import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Dropdown,
  Navbar,
  DropdownButton,
  Button,
  ListGroup,
  ListGroupItem,
  Image,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import IMAGES from "../assets/images";
import { baseApi } from "../services/baseApi";
import useFuseSearch from "../hooks/useFuseSearch";
import _ from "lodash";
import { jwtDecode } from "jwt-decode";

import { checkPermissionsAll } from "../utils/checkPermissions";

const Header = ({ toggleAside, menuButtonRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");
  const { results, isLoading } = useFuseSearch(debouncedSearchInput);
  const [showResults, setShowResults] = useState(false);

  const searchRef = useRef(null);
  const resultsRef = useRef(null);

  const logoutHandler = () => {
    localStorage.clear();
    dispatch(
      baseApi.util.invalidateTags([
        "guides",
        "docs",
        "profile",
        "folders",
        "role-classes",
        "roles",
      ])
    );
    navigate("/login");
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    toggleAside();
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  // Debounce the search input
  const debouncedHandleSearchChange = useCallback(
    _.debounce((input) => {
      setDebouncedSearchInput(input);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedHandleSearchChange(searchInput);
  }, [searchInput, debouncedHandleSearchChange]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  // Click handlers for different types
  const handleResultClick = (result) => {
    setShowResults(false); // Hide the results list on click
    const encodedName = encodeURIComponent(result.name);
    if (result.type === "folder") {
      navigate(`/documents-center/${result._id}`);
    } else if (result.type === "document") {
      navigate(`/file/${result._id}`, { state: { result } });
    } else if (result.type === "guide") {
      navigate(`/guides?search=${encodedName}`);
    }
  };

  const getResultImage = (type) => {
    switch (type) {
      case "folder":
        return IMAGES.IconFolder;
      case "document":
        return IMAGES.IconFile;
      case "guide":
        return IMAGES.IconGuide;
      case "updates":
        return IMAGES.IconUpdate;
      default:
        return IMAGES.IconFile;
    }
  };

  const handleClickOutside = (event) => {
    if (
      resultsRef.current &&
      !resultsRef.current.contains(event.target) &&
      searchRef.current &&
      !searchRef.current.contains(event.target)
    ) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const userAccess = checkPermissionsAll(user, [
    "view_user",
    "add_user",
    "modify_user",
    "delete_user",
  ]);

  useEffect(() => {
    const token = localStorage.getItem("token"); // Get token from local storage

    const decodedToken = jwtDecode(token);
    // Logout only if the token exists but the user is not set
    if (decodedToken.exp) {
      logoutHandler();
    }
  }, [user]);

  return (
    <>
      <Navbar className="w-100 p-0 mb-0">
        <div className="d-flex justify-content-between header">
          <div className="leftSide">
            <Button
              ref={menuButtonRef} // Assign ref to the button
              onClick={handleMenuClick}
              variant="outline-light btn-icon toggle-button"
            >
              <FeatherIcon icon="menu" />
            </Button>
          </div>

          <div
            className={`rightSide d-flex align-items-center ${
              showResults ? "show-results" : ""
            }`}
          >
            <Form onSubmit={handleSearchSubmit} ref={searchRef}>
              <div className="icon-input left w-100">
                <Form.Control
                  type="search"
                  placeholder="Search keyword"
                  value={searchInput}
                  onChange={handleSearchChange}
                />
                <Button variant="none text-body" type="submit" className="px-0">
                  <FeatherIcon icon="search" style={{ cursor: "pointer" }} />
                </Button>
              </div>
              {showResults && debouncedSearchInput && (
                <ListGroup ref={resultsRef} className="search-results">
                  <div className="search-scroll">
                    {isLoading ? (
                      <ListGroupItem>Loading...</ListGroupItem>
                    ) : results.length > 0 ? (
                      results.map((result) => (
                        <ListGroupItem
                          key={result._id}
                          onClick={() => handleResultClick(result)}
                          style={{ cursor: "pointer" }}
                          className="search-list d-flex align-content-center gap-3 small fw-semibold"
                        >
                          <img
                            src={getResultImage(result.type)}
                            className="icon-xs"
                            alt=""
                          />
                          {result.name}
                        </ListGroupItem>
                      ))
                    ) : (
                      <ListGroupItem>No results found</ListGroupItem>
                    )}
                  </div>
                </ListGroup>
              )}
            </Form>
            <DropdownButton
              id="dropdown-item-button"
              title={
                <div className="d-flex align-items-center gap-2">
                  <div className="text-end">
                    <div>
                      Hi,{" "}
                      <span className="h6">{`${user?.firstName}  ${user?.lastName}`}</span>
                    </div>
                    <p className="text-muted mb-0 small">{user?.userType}</p>
                  </div>
                  <img
                    src={user?.profilePhoto || IMAGES.IconIdea}
                    className="avatar"
                    alt=""
                  />
                </div>
              }
              align="end"
              variant="transparent text-body no-arrow-dropdown"
              noCaret
            >
              <Link to="/profile-settings">
                <Dropdown.Item as="button">View Profile</Dropdown.Item>
              </Link>
              {userAccess && (
                <Link to="/user-management">
                  <Dropdown.Item as="button">User Management</Dropdown.Item>
                </Link>
              )}
              <Dropdown.Item as="button" onClick={logoutHandler}>
                Logout
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
