import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import ComponentLoader from "../../../../components/Loader/ComponentLoader";
import UserTable from "../../../../components/Tables/ReusableTable";
import AlertModal from "./alertModal";

import { Notification } from "../../../../components/ToastNotifcaiton";

import {
  useGetAlertsQuery,
  useUpdateAlertMutation,
  useCreateAlertMutation,
  useDeleteAlertMutation
} from "../../../../services/dashboard";

import { usersApi } from "../../../../services/user";

import { checkPermissions } from "../../../../utils/checkPermissions";

const Alerts = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);

  const [show, setShow] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: ""
  });
  const [isAlertLoading, setIsAlertLoading] = useState(false);

  const { data, isLoading } = useGetAlertsQuery(undefined);
  const [updateAlert, { isLoading: isUpdating }] = useUpdateAlertMutation();
  const [createAlert, { isLoading: isCreating }] = useCreateAlertMutation();
  const [deleteAlert, { isLoading: isDeleting, refetch }] =
    useDeleteAlertMutation();

  useEffect(() => {
    if (selectedAlertId) {
      const selectedAlert = data?.data.find(
        (item) => item._id === selectedAlertId
      );
      if (selectedAlert) {
        setFormData({
          title: selectedAlert.title,
          description: selectedAlert.description
        });
      }
    } else {
      setFormData({ title: "", description: "" });
    }
  }, [selectedAlertId, data]);

  const handleShow = () => {
    setShow(true);
    dispatch(
      usersApi.util.invalidateTags([
        { type: "SingleAlert", id: selectedAlertId }
      ])
    );
  };

  const handleClose = () => {
    setShow(false);
    setSelectedAlertId(null);
    setFormData({ title: "", description: "" });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsAlertLoading(true);

    if (!formData.title || !formData.description) {
      Notification("All fields are required", "error");
      setIsAlertLoading(false);
      return;
    }

    const payload = {
      id: selectedAlertId,
      body: {
        title: formData.title,
        description: formData.description
      }
    };

    try {
      if (selectedAlertId) {
        await updateAlert(payload);
        Notification("Alert Updated successfully", "success");
      } else {
        await createAlert(payload.body);
        Notification("Alert Added successfully", "success");
      }
      handleClose();
    } catch (error) {
      Notification("Error while submitting", "error");
      console.error("Error saving alert:", error);
    } finally {
      setIsAlertLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteAlert(selectedAlertId).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to delete Alert:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedAlertId(data?._id);
    handleShow();
  };

  const headers = [
    { label: "Title", dataField: "title", widthClass: "w-25" },
    { label: "Description", dataField: "description", widthClass: "w-75" }
  ];

  const canAdd = checkPermissions(user, "add_content");
  const canModify = checkPermissions(user, "modify_content");
  const canDelete = checkPermissions(user, "delete_content");

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div>
      <Row className="filter align-items-center justify-content-between gap-3 mb-3">
        <Col sm="6" xl="4" className="order-2 order-sm-1"></Col>
        {canAdd && (
          <Col sm="auto" className="order-1 order-sm-2">
            <div className="d-flex justify-content-end flex-wrap gap-2">
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedAlertId(null);
                  handleShow();
                }}
              >
                <FeatherIcon icon="plus" /> Add
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <UserTable
        headers={headers}
        data={data?.data}
        handleRowClick={handleRowClick}
      />

      <AlertModal
        show={show}
        handleClose={handleClose}
        selectedAlertId={selectedAlertId}
        handleFormSubmit={handleFormSubmit}
        handleFormChange={handleFormChange}
        formData={formData}
        isUpdating={isAlertLoading}
        isCreating={isAlertLoading}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        id={selectedAlertId}
        canAdd={canAdd}
        canModify={canModify}
        canDelete={canDelete}
      />
    </div>
  );
};

export default Alerts;
