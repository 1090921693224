import React, { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import UserManagementTab from "./UserManagementTab";
import UserClassManagementTab from "./UserClassManagementTab";

function UserManagement() {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showClassModal, setShowClassModal] = useState(false);

  const handleUserShow = () => setShowUserModal(true);
  const handleUserClose = () => setShowUserModal(false);
  const handleClassShow = () => setShowClassModal(true);
  const handleClassClose = () => setShowClassModal(false);

  return (
    <div>
      <h2 className="page-heading">User Management</h2>
      <Row>
        <Col xs="12">
          <Tabs
            defaultActiveKey="UserManagement"
            id="user-management-tabs"
            className="mb-4"
          >
            <Tab eventKey="UserManagement" title="User Management">
              <UserManagementTab
                handleShow={handleUserShow}
                show={showUserModal}
                handleClose={handleUserClose}
              />
            </Tab>
            <Tab eventKey="UserClassManagement" title="User Class Management">
              <UserClassManagementTab
                handleShow={handleClassShow}
                show={showClassModal}
                handleClose={handleClassClose}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default UserManagement;
