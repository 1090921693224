// src/components/RedirectListener.js
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearRedirectTo } from "../redux/slices/uiSlices";

function RedirectListener() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectTo = useSelector((state) => state.ui.redirectTo);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
      dispatch(clearRedirectTo()); // Clear the redirect state to prevent repeated redirections
    }
  }, [redirectTo, navigate, dispatch]);

  return null;
}

export default RedirectListener;
