import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, Image, Spinner } from "react-bootstrap";
import { useUpdatePartnersDocumentMutation } from "../../services/partners";
import { Notification } from "../../components/ToastNotifcaiton";
import IMAGES from "../../assets/images";

const UpdateDocumentModal = ({ show, handleClose, documentData }) => {
  const [documentName, setDocumentName] = useState("");
  const [updateDocument, { isLoading: isUpdating }] =
    useUpdatePartnersDocumentMutation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (documentData) {
      setDocumentName(documentData.name);
    } else {
      setDocumentName("");
    }
  }, [documentData]);

  useEffect(() => {
    if (show) {
      inputRef.current && inputRef.current.focus();
    }
  }, [show]);

  const handleUpdate = async () => {
    if (!documentName.trim()) {
      Notification("Document name is required", "warning");
      return;
    }

    try {
      await updateDocument({
        folderId: documentData.folderId,
        documentId: documentData._id,
        name: documentName
      }).unwrap();
      Notification("Document updated successfully", "success");
      handleClose();
      setDocumentName("");

      // Optimization: Need to Update
      window.location.reload();
    } catch (error) {
      Notification(error?.data?.error || "Failed to update document", "error");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if form is used
      handleUpdate();
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Image src={IMAGES.IconFile} alt="Update Document" />
            Update Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Document Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter document name"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                onKeyDown={handleKeyPress}
                ref={inputRef}
              />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isUpdating} variant="light" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            disabled={isUpdating}
            variant="primary"
            onClick={handleUpdate}
          >
            {isUpdating ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Update"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateDocumentModal;
