import { baseApi } from "../baseApi";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "users/login",
        method: "POST",
        body: credentials
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: "users/logout",
        method: "POST"
      })
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: "users/change-password",
        method: "POST",
        body: credentials
      })
    })
  })
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation
} = authApi;
