import { useState, useEffect } from "react";
import { ReactDragDropUpload } from "react-drag-drop-upload";
import { AiOutlineCloseCircle } from "react-icons/ai";

function UploadFile({ onFileSelect, imgLink, setImage }) {
  const [fileData, setFileData] = useState(null);
  const [imageUrl, setImageUrl] = useState(imgLink);

  useEffect(() => {
    setImageUrl(imgLink);
  }, [imgLink]);

  const handleChange = (data) => {
    setFileData(data);
    onFileSelect(data);
    setImageUrl(null);
    setImage(null);
  };

  const handleRemoveFile = () => {
    setFileData(null);
    onFileSelect(null);
    setImageUrl(null);
    setImage(null);
  };

  return (
    <div className="flex flex-col items-center justify-center react-drag-drop-upload w-100">
      <div className="upload-area" style={{ position: "relative" }}>
        {!fileData && !imageUrl && (
          <ReactDragDropUpload
            handleChange={handleChange}
            className="rounded text-center w-100"
            types={["JPG", "PNG"]}
            multiple={false}
            variant="small"
          />
        )}
        {(fileData || imageUrl) && (
          <div
            className="image-preview-wrapper d-flex align-items-center justify-content-center"
            style={{ width: "100%", height: "100%" }}
          >
            <img
              src={fileData ? URL.createObjectURL(fileData) : imageUrl}
              className="object-fit-cover"
              alt="Uploaded file"
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
            <button
              onClick={handleRemoveFile}
              className="remove-image-btn"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none"
              }}
            >
              <AiOutlineCloseCircle size={18} color="red" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
