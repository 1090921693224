import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Form,
  Image,
  Button,
  Container,
  Spinner
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import { Link, useNavigate } from "react-router-dom";

import { useLoginMutation } from "../../services/auth";

import { Notification } from "../../components/ToastNotifcaiton";

import IMAGES from "../../assets/images";

const Login = () => {
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState({
    email: "",
    password: ""
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [openEye, setOpenEye] = useState(false);

  const [login, { isLoading }] = useLoginMutation();

  const token = localStorage.getItem("token");

  const handleEyeOpen = () => {
    setOpenEye(!openEye);
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  // General handler for form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  // Handler for checkbox change
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await login({ ...formFields });

      if (result?.error?.status === 302) {
        Notification(`${result?.error?.data?.message}`, "success");
        navigate("/change-password", {
          state: { email: formFields.email }
        });
      }

      if (result?.data?.success) {
        Notification("Logged In Successfully", "success");
        navigate("/");
      } else {
        Notification(result?.error?.data?.error, "error");
      }
    } catch (err) {
      console.error("Failed to login:", err);
      Notification(err, "error");
    }
  };

  return (
    <div
      className="riz-portal d-flex align-items-center justify-content-center w-100"
      style={{
        backgroundImage: `url(${IMAGES.login})`,
        backgroundColor: "var(--bs-primary)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col sm="10" md="8" lg="5">
            <Card className="card-login rounded-5 border-0">
              <Card.Body className="p-5">
                <Form onSubmit={handleSubmit}>
                  <div className="text-center mb-4">
                    <div className="d-flex align-items-center justify-content-center w-100 mb-5">
                      <Image
                        src={IMAGES.LogoLight}
                        alt="CYGNUS Logo"
                        className="logo"
                      />
                    </div>
                    <h4 className="text-white mb-3">Login to CYGNUS Portal</h4>
                    <p className="text-white mb-0">
                      A single space to access all of documents and guides
                    </p>
                  </div>
                  <Form.Group className="form-group">
                    <Form.Label className="text-white">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={formFields.email}
                      onChange={handleChange}
                      name="email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Password</Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={openEye ? "text" : "password"}
                        placeholder="Enter your password"
                        value={formFields.password}
                        onChange={handleChange}
                        name="password"
                      />
                      <span
                        onClick={handleEyeOpen}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                      >
                        {openEye ? (
                          <FeatherIcon icon="eye-off" />
                        ) : (
                          <FeatherIcon icon="eye" />
                        )}
                      </span>
                    </div>
                  </Form.Group>
                  <Row className="align-items-center form-group">
                    <Col xs="6">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className="text-white"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          className="fw-bold"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="6" className="text-end">
                      <Link
                        to="/forget-password"
                        className="text-white fw-bold"
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  >
                    {isLoading ? (
                      <Spinner color="light" size="sm" />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
