/* eslint-disable react/prop-types */
import { Oval } from "react-loader-spinner";
import "./index.css";

const ComponentLoader = ({ height, width }) => {
  return (
    <div className="table-loader">
      <Oval
        ariaLabel="oval-loading"
        secondaryColor="var(--bs-light)"
        strokeWidth={2}
        strokeWidthSecondary={2}
        height={height ? height : "50"}
        width={width ? width : "50"}
        color="var(--bs-primary)"
        visible={true}
      />
    </div>
  );
};

export default ComponentLoader;
