import React from "react";
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Card,
  Image,
  CardBody
} from "react-bootstrap";
import IMAGES from "../../assets/images";

const getFileExtension = (url) => {
  return url.split(".").pop().toLowerCase().split("?")[0];
};

const isImageUrl = (url) => {
  const extension = getFileExtension(url);
  return ["jpg", "jpeg", "png", "gif"].includes(extension);
};

const isVideoUrl = (url) => {
  const extension = getFileExtension(url);
  return ["mp4", "webm", "ogg"].includes(extension);
};

const renderCellContent = (value, isImageHeader, isLongContent) => {
  if (typeof value === "string") {
    if (isImageUrl(value)) {
      return (
        <img
          src={value}
          alt="table content"
          className="table-image"
          onError={(e) => (e.target.src = IMAGES.NewsDefaultImage)}
        />
      );
    } else if (isVideoUrl(value)) {
      return (
        <video controls className="table-video">
          <source src={value} type={`video/${getFileExtension(value)}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (isLongContent) {
      return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{value}</Tooltip>}>
          <span className="truncated-text">{value}</span>
        </OverlayTrigger>
      );
    }
  }

  if (isImageHeader && !value) {
    return (
      <img
        src={IMAGES.NewsDefaultImage}
        alt="table content"
        className="table-image"
      />
    );
  }

  return value;
};

const UpdatesTable = ({ headers, data, handleRowClick }) => {
  const renderCell = (content, header) => {
    if (Array.isArray(content)) {
      return (
        <div className="d-flex flex-wrap gap-2">
          {content.map((item, idx) => (
            <div
              key={idx}
              className="d-flex align-items-center px-3 py-2 bg-light rounded cursor-default fw-semibold text-body text-nowrap"
            >
              {item}
            </div>
          ))}
        </div>
      );
    } else {
      const isImageHeader = header.label.toLowerCase() === "image";
      const isLongContent =
        header.label.toLowerCase() === "long content header"; // Change this to match your actual header label
      return renderCellContent(content, isImageHeader, isLongContent);
    }
  };

  return (
    <Card className="overflow-hidden ">
      <Card.Title className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <Image src={IMAGES.IconUpdate} className="icon-sm" alt="Home" />
          Products & Alerts
        </div>
      </Card.Title>
      <CardBody>
        <Table responsive striped>
          <tbody>
            {data?.length > 0 ? (
              data.map((item, index) => (
                <tr
                  className="icon-hover"
                  key={index}
                  onClick={() => handleRowClick(item)}
                >
                  <td>
                    <div className="d-flex align-items-center gap-4">
                      {renderCell(item[headers[0].dataField], headers[0])}
                      <div>
                        <p class="mb-2 small text-muted">
                          {renderCell(item[headers[2].dataField], headers[2])}
                        </p>
                        <h6 className="mt-0 mb-1">
                          {renderCell(item[headers[1].dataField], headers[1])}
                        </h6>
                        <p className="mb-0">
                          {renderCell(item[headers[3].dataField], headers[3])}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default UpdatesTable;
