import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Image,
  Form,
  Card,
  Badge,
  Modal
} from "react-bootstrap";

import IMAGES from "../../assets/images";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";

function InventoryManagementDetail() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Image src={IMAGES.IconRocket} alt="Upload Document" />
            Deploy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="form-group">
              <Form.Label>Merchant ID</Form.Label>
              <Form.Control type="text" placeholder=""/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Merchant DBA</Form.Label>
              <Form.Control type="text" placeholder=""/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleClose}>Save</Button>
        </Modal.Footer>
      </Modal>

      <div class="page-heading d-flex align-items-top gap-3">
        <Link to="/inventory-management">
          <Button variant="outline-light btn-icon">
            <FeatherIcon icon="chevron-left" />
          </Button>
        </Link>
        <h2 className="m-0">Terminal Inventory Management</h2>
      </div>

      <Row>
        <Col xs="12">

          <Row className="mb-5">
            <Col xs="auto">
              <Image src={IMAGES.img2} alt='' width={'170px'} height={'170px'} className="rounded object-fit-cover"/>
            </Col>
            <Col>
              <h3 className="mt-0 mb-3">Pax A80</h3>
              <div className="d-flex flex-column gap-1">
                <div className=""><span className="fw-bold">Serial Number:</span> 12405625124</div>
                <div><span className="fw-bold">Terminal Condition:</span> <Badge bg="success">New</Badge></div>
                <div><span className="fw-bold">Encryption:</span> XXXX</div>
                <div><span className="fw-bold">Merchant ID:</span> 677500000000201</div>
                <div><span className="fw-bold">Merchant DBA:</span> Cygnus Retail</div>
              </div>
            </Col>
            <Col xs="auto">
              <Button variant="primary" onClick={handleShow}>
                <RocketLaunchIcon className="icon-sm"/>
                Deploy
              </Button>
            </Col>
          </Row>

          <Card>
            <Card.Body className="p-4">
              <h4 className="mt-0 mb-4">Comments (06)</h4>
              <Row className="my-3">
                <Col xs="auto" className="pe-1">
                  <Image src={IMAGES.img2} className="avatar" alt=''/>
                </Col>
                <Col>
                  <Form.Control as="textarea" rows={3} className="p-3" placeholder="Type a comment ..."/>
                </Col>
                <Col xs="12" className="mt-3 d-flex justify-content-end gap-2">
                  <Button variant="light" className="btn-100">Cancel</Button>
                  <Button variant="primary" className="btn-100">Comment</Button>
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs="auto" className="pe-1">
                  <Image src={IMAGES.img1} className="avatar" alt=''/>
                </Col>
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mt-0 mb-1">Rizwan Akram</h6>
                    <span className="small text-body-secondary">1 hour ago</span>
                  </div>
                  <p className="mb-1">In mauris porttitor tincidunt mauris massa sit lorem sed scelerisque. Fringilla pharetra vel massa enim sollicitudin cras. At pulvinar eget sociis adipiscing eget donec ultricies nibh tristique.</p>
                  <Link to="/" className="small text-body-secondary">Reply <span className="fw-bold">21</span></Link>
              
                  <Row className="my-3">
                    <Col xs="auto" className="pe-1">
                      <Image src={IMAGES.img2} className="avatar" alt=''/>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mt-0 mb-1">Rizwan Akram</h6>
                        <span className="small text-body-secondary">2 days ago</span>
                      </div>
                      <p className="mb-1">In mauris porttitor tincidunt mauris massa sit lorem sed scelerisque. Fringilla pharetra vel massa enim sollicitudin cras. At pulvinar eget sociis adipiscing eget donec ultricies nibh tristique.</p>
                      <Link to="/" className="small text-body-secondary">Reply <span className="fw-bold">21</span></Link>
                      <Row className="my-2">
                        <Col>
                          <Form.Control as="textarea" rows={2} className="p-3" placeholder="Type a comment ..."/>
                        </Col>
                        <Col xs="12" className="mt-3 d-flex justify-content-end gap-2">
                          <Button variant="light" className="btn-100">Cancel</Button>
                          <Button variant="primary" className="btn-100">Comment</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs="auto" className="pe-1">
                  <Image src={IMAGES.img3} className="avatar" alt=''/>
                </Col>
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mt-0 mb-1">Rizwan Akram</h6>
                    <span className="small text-body-secondary">5 min ago</span>
                  </div>
                  <p className="mb-1">In mauris porttitor tincidunt mauris massa sit lorem sed scelerisque. Fringilla pharetra vel massa enim sollicitudin cras. At pulvinar eget sociis adipiscing eget donec ultricies nibh tristique.</p>
                  <Link to="/" className="small text-body-secondary">Reply <span className="fw-bold">21</span></Link>
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs="auto" className="pe-1">
                  <Image src={IMAGES.img6} className="avatar" alt=''/>
                </Col>
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mt-0 mb-1">Rizwan Akram</h6>
                    <span className="small text-body-secondary">Yesterday</span>
                  </div>
                  <p className="mb-1">In mauris porttitor tincidunt mauris massa sit lorem sed scelerisque. Fringilla pharetra vel massa enim sollicitudin cras. At pulvinar eget sociis adipiscing eget donec ultricies nibh tristique.</p>
                  <Link to="/" className="small text-body-secondary">Reply <span className="fw-bold">21</span></Link>
                </Col>
              </Row>
              <div className="text-center">
                <Link to="/" className="fw-bold text-body-secondary small">See 10 more comments</Link>
              </div>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </div>
  );
}

export default InventoryManagementDetail;
