import React, { useState } from "react";
import {
  Button,
  Form,
  Modal,
  Spinner,
  InputGroup,
  FormControl
} from "react-bootstrap";
import DeleteModal from "../../../../components/Modals/DeleteItem";

const PollModal = ({
  show,
  handleClose,
  selectedPollId,
  handleFormSubmit,
  handleFormChange,
  formData,
  isUpdating,
  isCreating,
  onDelete,
  isDeleting,
  id,
  canAdd,
  canModify,
  canDelete
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete();
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleOptionChange = (index, value) => {
    const newOptions = [...formData.options];
    newOptions[index].text = value;
    handleFormChange({ target: { name: "options", value: newOptions } });
  };

  const handleAddOption = () => {
    if (formData.options.length < 10) {
      const newOptions = [...formData.options, { text: "" }];
      handleFormChange({ target: { name: "options", value: newOptions } });
    }
  };

  const handleRemoveOption = (index) => {
    const newOptions = formData.options.filter((_, idx) => idx !== index);
    handleFormChange({ target: { name: "options", value: newOptions } });
  };

  const handleRatingQuestionChange = (index, value) => {
    const newRatingQuestions = [...formData.ratingQuestions];
    newRatingQuestions[index].question = value;
    handleFormChange({
      target: { name: "ratingQuestions", value: newRatingQuestions }
    });
  };

  const handleAdditionalQuestionChange = (index, value) => {
    const newAdditionalQuestions = [...formData.additionalQuestions];
    newAdditionalQuestions[index].question = value;
    handleFormChange({
      target: { name: "additionalQuestions", value: newAdditionalQuestions }
    });
  };

  const handleToggleRating = () => {
    const newRatingEnabled = !formData.ratingEnabled;
    handleFormChange({
      target: { name: "ratingEnabled", value: newRatingEnabled }
    });
    if (newRatingEnabled && formData.ratingQuestions.length === 0) {
      handleFormChange({
        target: {
          name: "ratingQuestions",
          value: [{ question: "", ratings: [] }]
        }
      });
    }
  };

  const handleToggleAdditionalQuestions = () => {
    const newAdditionalQuestionsEnabled = !formData.additionalQuestionsEnabled;
    handleFormChange({
      target: {
        name: "additionalQuestionsEnabled",
        value: newAdditionalQuestionsEnabled
      }
    });
    if (
      newAdditionalQuestionsEnabled &&
      formData.additionalQuestions.length === 0
    ) {
      handleFormChange({
        target: {
          name: "additionalQuestions",
          value: [{ question: "", answers: [] }]
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.options.length < 3) {
      alert("You must provide at least 3 options.");
      return;
    }

    if (
      formData.ratingEnabled &&
      (!formData.ratingQuestions ||
        formData.ratingQuestions.length === 0 ||
        formData.ratingQuestions[0].question === "")
    ) {
      alert("Rating questions cannot be empty when rating is enabled.");
      return;
    }

    if (
      formData.additionalQuestionsEnabled &&
      (!formData.additionalQuestions ||
        formData.additionalQuestions.length === 0 ||
        formData.additionalQuestions[0].question === "")
    ) {
      alert(
        "Additional questions cannot be empty when additional questions are enabled."
      );
      return;
    }

    const payload = {
      id: selectedPollId,
      body: {
        question: formData.question,
        options: formData.options,
        additionalQuestions: formData.additionalQuestions,
        ratings: formData.ratingEnabled ? formData.ratingQuestions : []
      }
    };

    handleFormSubmit(payload);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animation
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedPollId ? "Edit Poll" : "Add Poll"}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="formQuestion">
              <Form.Label>Poll Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={formData.question}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            {formData.options.map((option, index) => (
              <InputGroup className="mb-3" key={index} className="mt-3">
                <FormControl
                  type="text"
                  placeholder={`Option ${index + 1}`}
                  value={option.text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  required
                />
                <div className="input-group-append ">
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveOption(index)}
                  >
                    Remove
                  </Button>
                </div>
              </InputGroup>
            ))}
            <Button
              variant="primary"
              onClick={handleAddOption}
              disabled={formData.options.length >= 10}
              className="mt-3"
            >
              Add Option
            </Button>
            <Form.Group className="mt-3">
              <Form.Check
                type="switch"
                id="rating-enabled"
                label="Enable Rating"
                checked={formData.ratingEnabled}
                onChange={handleToggleRating}
              />
            </Form.Group>
            {formData.ratingEnabled && (
              <div>
                <Form.Label>Rating Questions</Form.Label>
                {formData.ratingQuestions.map((question, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    placeholder={`Rating Question ${index + 1}`}
                    value={question.question}
                    onChange={(e) =>
                      handleRatingQuestionChange(index, e.target.value)
                    }
                    className="mb-3"
                    required
                  />
                ))}
              </div>
            )}
            <Form.Group className="mt-3">
              <Form.Check
                type="switch"
                id="additional-questions-enabled"
                label="Enable Additional Questions"
                checked={formData.additionalQuestionsEnabled}
                onChange={handleToggleAdditionalQuestions}
              />
            </Form.Group>
            {formData.additionalQuestionsEnabled && (
              <div>
                <Form.Label>Additional Questions (Optional)</Form.Label>
                {formData.additionalQuestions.map((aq, index) => (
                  <div key={index} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder={`Additional Question ${index + 1}`}
                      value={aq.question}
                      onChange={(e) =>
                        handleAdditionalQuestionChange(index, e.target.value)
                      }
                      className="mb-2"
                      required
                    />
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {canDelete && (
              <div className="d-flex gap-2">
                {id && (
                  <Button
                    onClick={handleDelete}
                    variant="danger"
                    disabled={isUpdating || isCreating}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}

            {(canAdd || canModify) && (
              <Button
                variant="primary"
                type="submit"
                disabled={isUpdating || isCreating}
              >
                {isUpdating || isCreating ? (
                  <Spinner color="light" size="sm" />
                ) : selectedPollId ? (
                  "Save Changes"
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default PollModal;
