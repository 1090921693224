import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import DeleteModal from "../../../../components/Modals/DeleteItem";

const GalleryModal = ({
  show,
  handleClose,
  selectedClassId,
  handleFormSubmit,
  handleFormChange,
  imagePreviewUrl,
  formData,
  isUpdating,
  isCreating,
  onDelete,
  isDeleting,
  id,
  canAdd,
  canModify,
  canDelete
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await onDelete();
    setShowDeleteModal(false);
    handleClose();
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  };

  const isVideoUrl = (url) => {
    return /\.(mp4|webm|ogg)$/.test(url);
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        animation={true}
        size="lg"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedClassId ? "Edit Gallery Item" : "Add Gallery Item"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Body>
            <Form.Group controlId="formImage">
              <Form.Label>Image/Video</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/*,video/*"
                onChange={handleFormChange}
              />

              {imagePreviewUrl && (
                <div className="mt-3">
                  {isImageUrl(imagePreviewUrl) ? (
                    <img
                      src={imagePreviewUrl}
                      alt="Preview"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover"
                      }}
                    />
                  ) : isVideoUrl(imagePreviewUrl) ? (
                    <video
                      controls
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    >
                      <source
                        src={imagePreviewUrl}
                        type={`video/${imagePreviewUrl.split(".").pop()}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formName" className="mt-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            {canDelete && (
              <div className="d-flex gap-2">
                {id && (
                  <Button
                    onClick={handleDelete}
                    variant="danger"
                    disabled={isUpdating || isCreating}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}

            {(canAdd || canModify) && (
              <Button
                variant="primary"
                type="submit"
                disabled={isUpdating || isCreating}
              >
                {isUpdating || isCreating ? (
                  <Spinner color="light" size="sm" />
                ) : selectedClassId ? (
                  "Save Changes"
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDelete}
        handleDelete={handleConfirmDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default GalleryModal;
