import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlices";
import profileReducer from "./slices/profileSlices";
import { baseApi } from "../services/baseApi";
import { usersApi } from "../services/user";

import uiReducer from "./slices/uiSlices";
import { setRedirectTo } from "../redux/slices/uiSlices";

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    auth: authReducer,
    profile: profileReducer,
    ui: uiReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(usersApi.middleware)
});
