import { useState } from "react";
import { Form, Card, Image, Button } from "react-bootstrap";
import IMAGES from "../../assets/images";
import { MultiSelect } from "react-multi-select-component";

function CreateUserClass() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const options1 = [
    { label: "Agent", value: "Agent" },
    { label: "Internal", value: "Internal" },
    { label: "Admin", value: "Admin" }
  ];
  const options2 = [
    { label: "User Admin Setting", value: "User Admin Setting" },
    { label: "Upload Document", value: "Internal" },
    { label: "Edit/Upload Guides", value: "Admin" }
  ];
  const [selected1, PermissionsLevels] = useState([]);
  const [selected2, classesSelected] = useState([]);

  return (
    <div>
      <h2 className="page-heading">Admin Settings</h2>

      <Card className="section-card">
        <div className="section-card-heading">
          <h4 className="title">
            <Image src={IMAGES.IconPlusCircle} alt="" />
            Create User Class
          </h4>
        </div>
        <Form>
          <Form.Group className="form-group">
            <Form.Label>Classes</Form.Label>
            <MultiSelect
              options={options1}
              value={selected1}
              onChange={classesSelected}
              labelledBy="Select"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Permissions Levels</Form.Label>
            <MultiSelect
              options={options2}
              value={selected2}
              onChange={PermissionsLevels}
              labelledBy="Select"
            />
          </Form.Group>
          <div className="btn-action">
            <Button variant="light">Cancel</Button>
            <Button variant="primary">Save</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default CreateUserClass;
