import React, { useState, useRef, useEffect } from "react";
import { Card, Image, Modal, Button } from "react-bootstrap";
import moment from "moment";
import * as pdfjsLib from "pdfjs-dist/webpack";

import IMAGES from "../../assets/images";
import { Notification } from "../../components/ToastNotifcaiton";

// Set the worker src manually (if necessary)
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const AttachmentCard = ({ link, name, ext, date }) => {
  const formattedDate = moment(date).format("MMMM DD YYYY [at] hh:mm A");

  const [showDocModal, setShowDocModal] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfCanvasRef = useRef(null);

  const handleDownload = (e, fileUrl) => {
    e.stopPropagation();
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = name;
    a.click();
  };

  const handlePreview = (e) => {
    e.stopPropagation();
    const fileUrl = link;
    const fileExtension = ext.toLowerCase();

    setDocUrl(fileUrl);
    setFileType(fileExtension);

    if (
      [
        "pdf",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "svg",
        "mp4",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "doc",
        "docx"
      ].includes(fileExtension)
    ) {
      setShowDocModal(true);
    } else {
      handleDownload(e, fileUrl);
    }
  };

  const getFileIcon = (fileExtension) => {
    switch (fileExtension) {
      case "pdf":
        return IMAGES.IconPdf;
      case "doc":
      case "docx":
        return IMAGES.IconDoc;
      case "xls":
      case "xlsx":
        return IMAGES.IconXls;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "svg":
        return link;
      case "zip":
        return IMAGES.IconZip;
      case "exe":
        return IMAGES.IconExe;
      case "ppt":
      case "pptx":
        return IMAGES.IconPPT;
      default:
        return IMAGES.IconOther;
    }
  };

  const fileIcon = getFileIcon(ext?.toLowerCase());

  useEffect(() => {
    if (showDocModal && fileType === "pdf" && docUrl) {
      const loadPDF = async () => {
        try {
          const loadingTask = pdfjsLib.getDocument(docUrl);
          const pdf = await loadingTask.promise;

          setNumPages(pdf.numPages);

          const renderPage = async (pageNum) => {
            const page = await pdf.getPage(pageNum);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = pdfCanvasRef.current;
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };

            page.render(renderContext);
          };

          renderPage(pageNumber); // Render the initial page
        } catch (error) {
          console.error("Error loading PDF:", error);
          Notification("Failed to load document", "error");
          resetDocumentState();
        }
      };

      loadPDF();
    }
  }, [showDocModal, docUrl, fileType, pageNumber]);

  const resetDocumentState = () => {
    setNumPages(null);
    setPageNumber(1);
    setShowDocModal(false);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  return (
    <>
      <Card className="bg-light-hover">
        <Card.Body className="px-2 py-3">
          <div
            className="d-flex align-items-center justify-content-between px-2"
            style={{ cursor: "pointer" }}
            onClick={handlePreview}
          >
            <div className="d-flex align-items-center gap-2">
              <Image src={fileIcon} className="icon-lg" alt="Attachment" />
              <div>
                <h6 className="mt-0 mb-1">{name}</h6>
                <div className="d-flex align-items-center">
                  <small>{`Created - ${formattedDate}`}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1 link-to">
            <span
              className="text-primary cursor-pointer"
              onClick={(e) => handleDownload(e, link)}
            >
              Download
            </span>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showDocModal} onHide={resetDocumentState} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {fileType === "pdf" ? (
            <div>
              <canvas
                ref={pdfCanvasRef}
                style={{ width: "100%", height: "auto" }}
              ></canvas>
              <div className="d-flex justify-content-between mt-2">
                <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                  Previous
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : fileType === "mp4" ? (
            <video controls style={{ width: "100%", height: "100%" }}>
              <source src={docUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : ["png", "jpg", "jpeg", "gif", "svg"].includes(fileType) ? (
            <Image src={docUrl} className="w-100" alt="Document Preview" />
          ) : ["xls", "xlsx", "ppt", "pptx", "doc", "docx"].includes(
              fileType
            ) ? (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                docUrl
              )}`}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Document Preview"
            />
          ) : (
            <p>File type not supported for preview.</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AttachmentCard;
