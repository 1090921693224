import { baseApi } from "../baseApi";

export const fileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "files/upload",
          method: "POST",
          body: formData,
          headers: {
            Accept: "*/*"
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export const { useUploadFileMutation } = fileApi;
