/* eslint-disable react/prop-types */
import { Oval } from "react-loader-spinner";

import "./index.css";

const Loader = ({ height, width }) => {
  return (
    <div className="loader-component">
      <Oval
        ariaLabel="oval-loading"
        secondaryColor="var(--bs-light)"
        strokeWidth={2}
        strokeWidthSecondary={2}
        height={height ? height : "120"}
        width={width ? width : "120"}
        color="var(--bs-primary)"
        visible={true}
      />
    </div>
  );
};

export default Loader;
