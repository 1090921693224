import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { API_URL } from "../utils/constant";

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://localhost:5000/api/",
    // baseUrl: "http://192.168.18.63:5000/api/",
    baseUrl: "https://cygnus-portal-app.azurewebsites.net/api/",
    // baseUrl: "http://localhost:5000/api/",
    // credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      headers.set("Cache-Control", "no-cache");
      const token = getState().auth.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["guides", "docs", "profile", "polls", "users"],
  endpoints: () => ({})
});
